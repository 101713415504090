import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Colors } from "../Colors";
import { IconEnum } from "./Icons";
import styled from "styled-components";

const Container = styled.div<any>`
  color: ${(props) => props.color || Colors.Grey1};
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  padding: ${(props) => props.padding || "0"};
  background-color: ${(props) => props.backgroundColor || "transparent"};
  border-radius: ${(props) => props.borderRadius || "0"};
  margin-right: ${(props) => props.marginRight || "0"};
  display: ${(props) => props.display || ""};
  align-items: ${(props) => props.alignItems || ""};
  justify-content: ${(props) => props.justifyContent || ""};
  &:hover {
    color: ${(props) => {
    if (props.color === "inherit") return null;
    return props.hoverColor;
  }};
  }
`;

export const Icons = IconEnum;

export type IconProps = {
  icon: any;
  color?: Colors | string | null;
  hoverColor?: Colors | string | null;
  onClick?: any | null;
  size?: string | number | undefined;
  position?: string;
  top?: string | number | undefined;
  left?: string | number | undefined;
  right?: string | number | undefined;
  zIndex?: number | void;
  margin?: string | number | undefined;
  tip?: string;
  transitionDuration?: string;
  rotation?: number;
  width?: string | number;
  height?: string | number;
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string | number;
  marginRight?: string;
  display?: string;
  justifyContent?: string;
  alignItems?: string
  rest ?: any
};

export default function Icon({
  icon = Icons.AudienceRegular,
  color,
  hoverColor = null,
  onClick,
  size = 20,
  top,
  left,
  right,
  position = "relative" as any,
  zIndex,
  margin,
  tip,
  transitionDuration,
  rotation,
  width,
  height,
  padding,
  backgroundColor,
  borderRadius,
  marginRight,
  display,
  justifyContent,
  alignItems,
  ...rest
}: IconProps) {
  const cursor: string = onClick ? "pointer" : "";

  if (icon === Icons.TicketRegular) rotation = 90;
  if (icon === Icons.TicketSolid) rotation = 90;

  return (
    <Container color={color}
      hoverColor={hoverColor}
      width={width}
      height={height}
      padding={padding}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      marginRight={marginRight}
      data-tip={tip}
      display={display}
      alignItems={alignItems}
      justifyContent={justifyContent}
      {...rest}
      >
      <FontAwesomeIcon
        icon={icon as any}
        onClick={onClick}
        style={{
          top,
          left,
          right,
          position: !marginRight ? position as any : "",
          zIndex: zIndex as any,
          fontSize: size,
          transition: `all ${transitionDuration || "0.2s"}`,
          transform: rotation ? `rotate(${rotation}deg)` : undefined,
          margin,
          cursor,
        }}
      />
    </Container>
  );
}
