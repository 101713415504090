export enum FontsEnum {
  FontSize16 = "16px",
  FontSize14 = "14px",
  FontFamily = "inter",
  FontWeight500 = "500",
  FontWeight400 = "400",
  LineHeight19 = "19px",
  LetterSpacing = "-0.16px",
  TextTransformNone = "none",
  ContentAlign = "space-between",
  LetterSpacing8 = "-0.08px",
}
