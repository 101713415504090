import React from "react";
import styled from "styled-components";
import { Colors } from "../Colors";
import Tip from "./Tip";

type ContainerProps = {
  margin: string;
  height?:string
};

type TextProps = {
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  marginBottam?: string;
  letterSpacing?: string;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${(props) => props.margin};
  height: ${(props) => props.height || "15px"};
`;

const Text = styled.div<TextProps>`
  font-size: ${(props) => props.fontSize || "1.2rem"};
  font-weight: ${(props) => props.fontWeight || "600"};
  color: ${(props) => props.color || Colors.Grey1};
  //margin-right: 7px;
  font-family: ${(props) => props.fontFamily};
  margin-bottom: ${(props) => props.marginBottam};
  letter-spacing: ${(props) => props.letterSpacing};
`;

const SubText = styled.span`
  color: ${Colors.Grey3};
`;

type LabelProps = {
  text: string;
  subText?: string;
  tip?: string;
  margin?: string;
  labelColor?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  marginBottam?: string;
  letterSpacing?: string;
  height?:string;
};

const Label: React.FC<LabelProps> = ({
  text,
  subText,
  tip,
  labelColor,
  fontSize,
  fontWeight,
  fontFamily,
  marginBottam,
  margin = "0 0 8px",
  letterSpacing,
  height,
}) => {
  return (
    <Container margin={margin} height={height}>
      <Text
        color={labelColor}
        fontSize={fontSize}
        fontWeight={fontWeight}
        fontFamily={fontFamily}
        marginBottam={marginBottam}
        letterSpacing={letterSpacing}
      >
        {text}
        {subText && <SubText>&nbsp;{subText}</SubText>}
      </Text>
      {tip && <Tip tip={tip} />}
    </Container>
  );
};

export default Label;
