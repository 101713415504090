import React, { Fragment, useState } from "react";
import styled from "styled-components";
import * as Polished from "polished";
import AnimateHeight from "react-animate-height";
import { Colors } from "../Colors";
import Counter, { CounterProps } from "./Counter";
import * as PriceUtil from "@sellout/utils/.dist/price";
import * as Time from "@sellout/utils/.dist/time";

type RowProps = {
  justify?: string;
};
export enum TicketsEnum {
  Tickets = "Tickets",
  AddOns = "AddOns",
}
const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justify};
`;

const Column = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  background-color: ${Colors.SurfaceTertiary};
  margin: 0 24px;
  padding: 24px 0;
  border-bottom: 1px solid ${Colors.SecondaryStroke};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-size: 16px;
  color: ${Colors.HeadingSecondary};
  font-weight: 500;
  font-family: "Inter";
  line-height: 20px;
`;

const Price = styled.div`
  font-size: 16px;
  color: ${Colors.HeadingSecondary};
  font-weight: 500;
  margin: 5px 0;
  line-height: 90%;
`;

const Subtitle = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 160%;
  color: ${Colors.White};
  font-family: "inter";
`;

const Description = styled.div`
  color: ${Colors.SupportTertiary};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  font-family: "Inter";
  letter-spacing: -0.08px;
  word-break: break-word;
  overflow-wrap: break-word;
`;

const Tickets = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 160%;
  color: ${Colors.SupportTertiary};
  font-family: "inter";
`;

const Tag = styled.div`
  font-size: 16px;
  color: ${Colors.HeadingPrimary};
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 90%;
`;

const Value = styled.div`
  font-size: 28px;
  min-width: 20px;
  color: ${Colors.HeadingPrimary};
  line-height: 90%;
  font-weight: 500;
`;

type EllipsisProps = {
  active: boolean;
};

const Ellipsis = styled.div<EllipsisProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.active ? 3 : null)};
  -webkit-box-orient: ${(props) => (props.active ? "vertical" : null)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ShowMore = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  color: ${Colors.InteractiveBGPrimary};
  transition: all 0.2s;
  font-family: "Inter";
`;

const ShowMoreInner = styled.span`
  &:hover {
    cursor: pointer;
    color: ${Polished.lighten(0.025, Colors.Orange)};
  }

  &:active {
    color: ${Polished.darken(0.025, Colors.Orange)};
  }
`;
const DateTextWrapper = styled.span`
  margin: 7px 0px;
`;
const DateText = styled.span`
  font-size: 15px;
  font-weight: 500;
  color: ${Colors.HeadingSecondary};
  margin-bottom: 5px;
  padding-right: 5px;
`;

export type ProductProps = {
  title: string;
  isTicketScreen: string;
  price: number;
  isRSVP?: boolean;
  subtitle?: string;
  description?: string;
  imageUrl?: string;
  eventDays?: string[];
  isMultiDaysEvent?: boolean;
  timeZone?: string;
  remainingQty?: number;
} & CounterProps;

export default function Product({
  title = "",
  price = 0,
  subtitle = "",
  description = "",
  isRSVP = false,
  isTicketScreen,
  // Counter Props
  value,
  minValue,
  maxValue,
  onIncrement,
  onDecrement,
  eventDays,
  isMultiDaysEvent,
  timeZone,
  remainingQty = 0,
}: ProductProps) {
  const [showMore, setShowMore] = useState(false);
  const [showEllipsis, setShowEllipsis] = useState(true);

  let descModified = description;
  if (descModified.length > 210 && !showMore) {
    descModified = descModified.substring(0, 210) + "...";
  }
  

  const toggle = () => {
    setShowEllipsis(!showEllipsis);
    setShowMore(!showMore);
  };
  const ticketsAddOns =
    isTicketScreen === TicketsEnum.Tickets ? "Tickets" : "Add-Ons";
  return (
    <Container>
      <Column>
        <LeftWrapper>
          <Title>{title}</Title>
          {isMultiDaysEvent && (
            <DateTextWrapper>
              {eventDays &&
                eventDays.length > 0 &&
                eventDays?.map((day: any, i) => {
                  return (
                    <DateText key={i}>
                      {Time.format(day, "MMM Do", timeZone)}
                      {eventDays.length !== i + 1 && ","}
                    </DateText>
                  );
                })}
            </DateTextWrapper>
          )}
          {(() => {
            if (!description) return;

            return (
              <Fragment>
                <AnimateHeight height="auto">
                  <Ellipsis active={showEllipsis}>
                    <Description>{description}</Description>
                  </Ellipsis>
                </AnimateHeight>
                {descModified.length > 115 ? (
                  <ShowMore>
                    <ShowMoreInner onClick={() => toggle()}>
                      {showMore ? "Show Less" : "Show More"}
                    </ShowMoreInner>
                  </ShowMore>
                ) : (
                  ""
                )}
              </Fragment>
            );
          })()}
        </LeftWrapper>
        {isRSVP ? (
          PriceUtil.output(price, true) === "0.00" || 0 ? (
            <Price>{"RSVP"}</Price>
          ) : (
            <Price>
              {`$${PriceUtil.output(price, true)}` + " (Ticket value)"}
            </Price>
          )
        ) : (
          <Price>{`$${PriceUtil.output(price, true)}`}</Price>
        )}
      </Column>
      <Column>
        <Value>
          {value}
          <Tickets>{ticketsAddOns}</Tickets>
        </Value>

        {remainingQty > 0 ? (
          <Counter
            value={value}
            minValue={minValue}
            maxValue={maxValue}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
          />
        ) : (
          <Tag> Sold out</Tag>
        )}
      </Column>
      <Row>{subtitle && <Subtitle>{subtitle}</Subtitle>}</Row>
    </Container>
  );
}
