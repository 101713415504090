import React from "react";
import styled from "styled-components";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import { Colors } from "@sellout/ui/build/Colors";
import * as Time from "@sellout/utils/.dist/time";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import { media } from "@sellout/ui/build/utils/MediaQuery";
import Icon, { Icons } from "@sellout/ui/build/components/Icon";
import { ScreenEnum } from "../redux/reducers/app.reducer";
import EventUtil from "@sellout/models/.dist/utils/EventUtil";
import { PurchasePortalState } from "../redux/store";
import { SeatsioClient } from "seatsio";
import useSeatingKeysHook from "../hooks/useSeatingKeys.hook";
import BackButton from "./BackButton";
import PaymentUtil from "@sellout/models/.dist/utils/PaymentUtil";
import { Label } from "@sellout/ui";
import * as Price from "@sellout/utils/.dist/price";
import { FontsEnum } from "../models/enums/FontsEnum";
import { EPurchasePortalModes } from "@sellout/models/.dist/enums/EPurchasePortalModes";

type ContainerProps = {
  image?: string;
};

const Container = styled.div<ContainerProps>`
  padding: 24px;
  background: ${Colors.SecondarySurface};
  border-bottom: 1px solid ${Colors.SecondaryStroke};
`;

export const Heading = styled.div`
  margin: 25px 0 8px;
  font-size: 36px;
  line-height: 43px;
  color: ${Colors.HeadingSecondary};
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Sofia Sans Condensed";
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Image = styled.div<ContainerProps>`
  min-width: 80px;
  height: 50px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  border-radius: 8px;
`;

const Name = styled.div`
  color: ${Colors.HeadingSecondary};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  font-family: ${FontsEnum.FontFamily};
  letter-spacing: -0.08px;
  text-align: left;
`;

const InfoText = styled.div`
  font-family: ${FontsEnum.FontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.TextSupportSecondary};
`;

const Info = styled.div``;
export const Margin = styled.div`
  margin-top: 6px;
`;

const Text = styled.div`
  font-size: ${FontsEnum.FontSize16};
  line-height: 6px;
  font-weight: ${FontsEnum.FontWeight500};
  color: ${Colors.Red};
  align-items: right;
  font-family: ${FontsEnum.FontFamily};
`;

const Gradient = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));

  /* ${media.largeDesktop`
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 38.95%, rgba(0, 0, 0, 0.7) 100%);
  `}; */
`;



const Row = styled.div`
  display: flex;
  align-items: center;
  line-height: 2rem;
  * {
    line-height: 2rem;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Exit = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  z-index: 10000;
`;

const Timer = styled.div`
  position: absolute;
  top: 70px;
  right: 9px;
  ${media.handheld`
  top: 52px;
  `};
`;

export const HeaderText = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.08px;
  text-align: left;
  color: ${Colors.TextSupportSecondary};
`;

export const EditOrderButton = styled.div`
  background: ${Colors.InteractiveBGSecondary};
  color: ${Colors.HeadingSecondary};
  padding: 10px;
  font-size: 14px;
  display: flex;
  gap: 8px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-weight: normal;
  width: fit-content;
`;
export const OrderPrice = styled.div`
  font-family: Sofia Sans Condensed;
  font-size: 48px;
  font-weight: 800;
  line-height: 57.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${Colors.HeadingSecondary};
`;
type EventInfoProps = {
  event: Required<IEventGraphQL>;
};

const EventInfo: React.FC<EventInfoProps> = ({ event }) => {
  /** State **/
  const [timer, setTimer] = React.useState(0 as number);
  const [untilAnnounced, setUntilAnnounced] = React.useState(
    Time.formattedCountDown(Time.now(), timer as number)
  );
  const [session, setSession] = React.useState(false as boolean);
  const { app, user } = useSelector((state: PurchasePortalState) => state);
  const { venue } = event;
  const address = venue?.address;
  const {
    createUserParams: { email },
  } = user;
  const { isComplimentary } = app;
  const venueLocation = `${venue?.name || ""}${
    address ? `, ${address.city}, ${address.state}` : ""
  }`;
  let timezone = address?.timezone ? address?.timezone : "America/Denver";
  //
  const startsAt = moment(Time.date(event?.schedule?.startsAt))
    .tz(timezone)
    .format("ddd, MMM D [at] h:mmA");

  const isOnSale = event && EventUtil.isOnSale(event);

  const { fisrtScreen, waitList, loading } = app;
  /** State **/
  const dispatch = useDispatch();
  const {
    app: { mode, SeatingPlanTimer, screen },
    order,
  } = useSelector((state: PurchasePortalState) => state);
  const {
    appliedDiscount,
    createOrderParams: { tickets, upgrades, paymentMethodType },
  } = order;

  //////////////// For seating plan seats release /////////////
  let seats = [] as any;
  tickets && tickets.map((a) => a.seat && seats.push(a.seat));
  const { secretKey } = useSeatingKeysHook(event?.orgId as any);

  const closeApp = () => {
    dispatch(AppActions.closeApp());
    dispatch(AppActions.setScreen(fisrtScreen));
    if (seats.length > 0 && screen !== ScreenEnum.OrderConfirmed) {
      seatsRelease();
      const SeatingPlanTimer = 0;
      dispatch(AppActions.setSeatTimer({ SeatingPlanTimer }));
    }
  };

  const seatsRelease = async () => {
    const seatingId = EventUtil.seatingId(event);
    let seatsIOClient = new SeatsioClient(secretKey);
    return await seatsIOClient.events.release(seatingId, seats);
  };

  React.useEffect(() => {
    setTimer(SeatingPlanTimer);
    const interval = setInterval(() => {
      setUntilAnnounced(
        Time.formattedCountDown(Time.now(), SeatingPlanTimer as number)
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [SeatingPlanTimer]);

  React.useEffect(() => {
    const timers = Time.now();
    if (SeatingPlanTimer === timers) {
      const SeatingPlanTimer = 1;
      dispatch(AppActions.setSeatTimer({ SeatingPlanTimer }));
      setSession(true);
    }
  }, [untilAnnounced]);

  ///////////////// For Multidays ////////////////
  const firstDay =
    event?.schedule &&
    moment(Time.date(event?.schedule?.startsAt))
      .tz(timezone)
      .format("ddd, MMM D");
  const lastDay =
    event?.schedule &&
    moment(Time.date(event?.schedule?.endsAt))
      .tz(timezone)
      .format("ddd, MMM D");
  const setScreen = (screen: ScreenEnum) => {
    dispatch(AppActions.setScreen(screen));
  };
  const isBoxOffice = mode === EPurchasePortalModes.BoxOffice;
  const visibleUpgrades = event.upgrades.filter((upgrade) => upgrade.visible);

  const isSoldOut = !EventUtil.isSoldOut(event)
  const upgradesOnly =
  event.upgrades.length > 0 &&
  tickets.length === 0 && isSoldOut&& 
  screen === ScreenEnum.Tickets &&
  isBoxOffice &&
  visibleUpgrades.length > 0
  const screenContent = {
    Tickets: {
      heading: upgradesOnly ? "" :"TICKETS",
      showImage: true,
      showParagraph: false,
      paragraphText: "",
    },
    Upgrades: {
      heading: "ADD-ONS",
      showImage: true,
      showParagraph: false,
      paragraphText: "",
    },
    "User Email": {
      heading: "CHECKOUT",
      showImage: false,
      showParagraph: true,
      paragraphText:
        "Enter your email address to link this purchase to an existing account and we’ll email your tickets to you.",
    },
    "Phone Code": {
      heading: "WE EMAILED YOU",
      showImage: false,
      showParagraph: true,
      paragraphText: `We sent an email to ${email}<br /><br />If this email is associated with an account, you will receive an email.`,
    },
    "Add Payment": {
      heading: "MANUAL CARD ENTRY",
      showImage: false,
      showParagraph: true,
      paragraphText: `Select a payment method, and enter the card details below:`,
    },
    "Cash Payment": {
      heading: "CASH PAYMENT",
      showImage: false,
      showParagraph: true,
      paragraphText: `Enter the amount of cash the customer paid to record both the payment received and the change due to the customer.`,
    },
    "Custom Fields": {
      heading: "SURVEY QUESTIONS",
      showImage: true,
      showParagraph: false,
      paragraphText: "",
    },
    "Liability Waiver": {
      heading: "LIABILITY WAVIER",
      showImage: true,
      showParagraph: false,
      paragraphText: "",
    },
    "Payment Method": {
      heading: "SELECT PAYMENT METHOD",
      showImage: false,
      showParagraph: true,
      paragraphText: "Choose a payment method from the options below:",
    },
    ThirdScreen: {
      heading: "",
      showImage: false,
      showParagraph: true,
    },
  };
  const currentScreenConfig = screenContent[screen] || {
    heading: "",
    showImage: true,
    showParagraph: false,
    paragraphText: false,
  };
  const totalParams = {
    tickets,
    upgrades,
    fees: event.fees,
    paymentMethodType,
    promotions: appliedDiscount,
  };
  const ticketTotal = tickets?.reduce(
    (cur, ticket) => cur + parseFloat(ticket.values as string),
    0
  );
  const total =
    EventUtil.isRSVP(event as IEventGraphQL) || isComplimentary
      ? ticketTotal
      : PaymentUtil.calculatePaymentTotal(totalParams).total;

  /** Render **/

  return (
    <>
      <Container>
        {screen !== ScreenEnum.Tickets &&
          screen !== ScreenEnum.EventUnavailable &&
          !waitList &&
          !(screen === ScreenEnum.GuestMembers && loading) && <BackButton />}
        <Exit>
          <Icon
            icon={Icons.CancelCircle}
            color={Colors.HeadingSecondary}
            onClick={() => closeApp()}
            size={18}
          />
        </Exit>
        <Heading>{currentScreenConfig.heading}</Heading>

        <Flex>
          {currentScreenConfig.showImage && (
            <>
              <Image image={event?.posterImageUrl} />
              <Info>
                <Name>{event?.name || "Unnamed Event"}</Name>
                <InfoText>
                  {event?.isMultipleDays
                    ? firstDay + " - " + lastDay
                    : startsAt}
                </InfoText>
              </Info>
            </>
          )}
          {currentScreenConfig.showParagraph && (
            <HeaderText
              dangerouslySetInnerHTML={{
                __html: currentScreenConfig.paragraphText,
              }}
            />
          )}

          <Timer>
            {!session && untilAnnounced.length < 11 && (
              <Text>{untilAnnounced.slice(3)}</Text>
            )}
          </Timer>
        </Flex>
          {screen === ScreenEnum.ConfirmOrder && (
          <>
            <Label
              text={"Your Order"}
              fontFamily="inter"
              fontSize={"16px"}
              labelColor={Colors.HeadingSecondary}
              fontWeight={"400"}
              margin={"10px 0px 2px"}
              height={"auto"}
            ></Label>
            <OrderPrice>${Price.output(total, true)}</OrderPrice>
          </>
        )}
      </Container>
    </>
  );
};

export default EventInfo;
