import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { PurchasePortalState } from "../redux/store";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import { Colors } from "@sellout/ui/build/Colors";
import * as OrderActions from "../redux/actions/order.actions";
import * as Price from "@sellout/utils/.dist/price";
import PaymentUtil from "@sellout/models/.dist/utils/PaymentUtil";
import { ISeasonGraphQL } from "@sellout/models/.dist/interfaces/IOrder";
import { Spacer } from "./CreateWaitListInfo";
import { Button, Flex, Input, Label } from "@sellout/ui";
import { ButtonTypes } from "@sellout/ui/build/components/Button";
import { FontsEnum } from "../models/enums/FontsEnum";
import { InputSizes } from "@sellout/ui/build/components/Input";

type VectorSpacingProps = {
  width?: string;
};
const Container = styled.div`
  position: relative;
  top: -50px;
  background-color: ${Colors.SecondarySurface};
  border-radius: 15px 15px 0 0;
`;

const Content = styled.div`
  margin: 24px 0 0;
  padding: 0 24px;
`;

const CustomerOwesPrice = styled.div`
  font-family: Sofia Sans Condensed;
  font-size: 48px;
  font-weight: 800;
  line-height: 57.6px;
  letter-spacing: -0.01em;
  text-align: left;
  color: ${Colors.HeadingPrimary};
  letter-spacing: -0.16px
`;

const Text = styled.div`
  font-size: 1.2rem;
  color: ${Colors.Grey2};
  margin-top: 5px;
`;

export const VectorSpacing = styled.div<VectorSpacingProps>`
  width: ${(props) => (props.width ? "350px" : "342px")};
  height: 0px;
  border: 1px solid ${Colors.SecondaryStroke};
  flex: none;
  order: 2;
  align-self: stretch;Heading
  flex-grow: 0;
`;
enum CashTypePriceEnum {
  ExactCash = "Exact Cash",
  OneDollar = "$1",
  FiveDollar = "$5",
  TenDollar = "$10",
  TwentyDollar = "$20",
  FiftyDollar = "$50",
  HundredDollar = "$100",
}
type CashPaymentProps = {
  event?: IEventGraphQL;
  season?: ISeasonGraphQL;
};

const CashPayment: React.FC<CashPaymentProps> = ({ event, season }) => {
  /** State **/
  const { order } = useSelector((state: PurchasePortalState) => state);
  const {
    cashTendered,
    createOrderParams: { tickets, upgrades, paymentMethodType },
  } = order;

  /** Actions **/
  const dispatch = useDispatch();
  const setCashTendered = (cashTendered: any) => {
    dispatch(OrderActions.setCashTendered(cashTendered));
  };

  const totalParams = {
    tickets,
    upgrades,
    fees: event ? event?.fees : season?.fees,
    paymentMethodType,
  };

  const total = PaymentUtil.calculatePaymentTotal(totalParams as any).total;

  const handleButtonClick = (amount: number) => {
    const previous = Number(cashTendered);
    const newAmount = Number(amount) / 100;
    let sumTotalAmount = previous + newAmount;
    setCashTendered(sumTotalAmount.toFixed(2));
  };
  const exactCashButton = () => {
    setCashTendered((total / 100).toFixed(2));
  };
  return (
    <Container>
      <Content>
        <Input
          label="Cash Tendered"
          placeholder="0.00"
          size={InputSizes.CustomInputHeight}
          type="number"
          value={cashTendered ?? ("" as any)}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            setCashTendered(e.currentTarget.value);
          }}
          labelColor={`${Colors.HeadingSecondary}`}
          bgColor={`${Colors.SurfaceTertiary}`}
          fontSize={FontsEnum.FontSize16}
          fontWeight={FontsEnum.FontWeight500}
          fontFamily={FontsEnum.FontFamily}
          letterSpacing={FontsEnum.LetterSpacing}
          onFocusedColor={true}
          svgIconName={"cashIcon"}
          svgWidth={"19px"}
          svgHeight={"19px"}
        />

        <Spacer />
        <Flex justify="space-between" gap="10px">
          <Button
            type={ButtonTypes.Regular}
            text={CashTypePriceEnum.ExactCash}
            onClick={exactCashButton}
            bgColor={Colors.SecondaryStroke}
            textTransform={FontsEnum.TextTransformNone}
            CustomInputHeight={ButtonTypes.CustomInputHeight}
            fontFamily={FontsEnum.FontFamily}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            letterSpacing={FontsEnum.LetterSpacing}
            textColor={Colors.HeadingSecondary}
            lineHeight={FontsEnum.LineHeight19}
          />
          <Button
            type={ButtonTypes.Regular}
            text={CashTypePriceEnum.OneDollar}
            onClick={() => handleButtonClick(100)}
            bgColor={Colors.SecondaryStroke}
            CustomInputHeight={ButtonTypes.CustomInputHeight}
            fontFamily={FontsEnum.FontFamily}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            letterSpacing={FontsEnum.LetterSpacing}
            textColor={Colors.HeadingSecondary}
            lineHeight={FontsEnum.LineHeight19}
          />
          <Button
            type={ButtonTypes.Regular}
            text={CashTypePriceEnum.FiveDollar}
            onClick={() => handleButtonClick(500)}
            bgColor={Colors.SecondaryStroke}
            CustomInputHeight={ButtonTypes.CustomInputHeight}
            fontFamily={FontsEnum.FontFamily}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            letterSpacing={FontsEnum.LetterSpacing}
            textColor={Colors.HeadingSecondary}
            lineHeight={FontsEnum.LineHeight19}
          />
          <Button
            type={ButtonTypes.Regular}
            text={CashTypePriceEnum.TenDollar}
            onClick={() => handleButtonClick(1000)}
            bgColor={Colors.SecondaryStroke}
            CustomInputHeight={ButtonTypes.CustomInputHeight}
            fontFamily={FontsEnum.FontFamily}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            letterSpacing={FontsEnum.LetterSpacing}
            textColor={Colors.HeadingSecondary}
            lineHeight={FontsEnum.LineHeight19}
          />
        </Flex>
        <Spacer />
        <Flex gap="10px">
          <Button
            type={ButtonTypes.Regular}
            text={CashTypePriceEnum.TwentyDollar}
            onClick={() => handleButtonClick(2000)}
            bgColor={Colors.SecondaryStroke}
            CustomInputHeight={ButtonTypes.CustomInputHeight}
            fontFamily={FontsEnum.FontFamily}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            letterSpacing={FontsEnum.LetterSpacing}
            textColor={Colors.HeadingSecondary}
            lineHeight={FontsEnum.LineHeight19}
          />
          <Button
            type={ButtonTypes.Regular}
            text={CashTypePriceEnum.FiftyDollar}
            onClick={() => handleButtonClick(5000)}
            bgColor={Colors.SecondaryStroke}
            CustomInputHeight={ButtonTypes.CustomInputHeight}
            fontFamily={FontsEnum.FontFamily}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            letterSpacing={FontsEnum.LetterSpacing}
            textColor={Colors.HeadingSecondary}
            lineHeight={FontsEnum.LineHeight19}
          />
          <Button
            type={ButtonTypes.Regular}
            text={CashTypePriceEnum.HundredDollar}
            onClick={() => handleButtonClick(10000)}
            bgColor={Colors.SecondaryStroke}
            CustomInputHeight={ButtonTypes.CustomInputHeight}
            fontFamily={FontsEnum.FontFamily}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            letterSpacing={FontsEnum.LetterSpacing}
            textColor={Colors.HeadingSecondary}
            lineHeight={FontsEnum.LineHeight19}
          />
        </Flex>
        <Spacer />
        <VectorSpacing />
        <Spacer />
        <Label
          text={"Customer Owes:"}
          fontSize={FontsEnum.FontSize16}
          labelColor={Colors.HeadingSecondary}
          fontWeight={FontsEnum.FontWeight400}
          letterSpacing={FontsEnum.LetterSpacing}
          fontFamily={FontsEnum.FontFamily}
        ></Label>
        <CustomerOwesPrice>${Price.output(total, true)}</CustomerOwesPrice>
      </Content>
    </Container>
  );
};

export default CashPayment;
