import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import { PurchasePortalState } from "../redux/store";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import { Colors } from "@sellout/ui/build/Colors";
import CodeInput from "@sellout/ui/build/components/CodeInput";
import * as AppActions from "../redux/actions/app.actions";
import * as UserActions from "../redux/actions/user.actions";
import SEND_USER_PHONE_AUTHENTICATION from "@sellout/models/.dist/graphql/mutations/sendUserPhoneAuthentication.mutation";
import Error from "../components/Error";
import { ISeasonGraphQL } from "@sellout/models/.dist/interfaces/ISeason";
import { getErrorMessage } from "../utils/ErrorUtil";
import { AppNotificationTypeEnum } from "../models/interfaces/IAppNotification";
import { Spacer } from "./CreateWaitListInfo";
import { ErrorKeyEnum } from "../redux/reducers/app.reducer";
import { FontsEnum } from "../models/enums/FontsEnum";

type InputProps = {
  validationError?: string;
};
const Container = styled.div`
  position: relative;
  top: -50px;
  background-color: ${Colors.SecondarySurface};
  border-radius: 15px 15px 0 0;
  overflow: hidden;
`;

const Content = styled.div`
  margin: 24px 0 0;
  padding: 0 24px;
`;

const Text = styled.div`
  font-size: 16px;
  font-family: "inter";
  font-weight: 500;
  color: ${Colors.TextSupportSecondary};
  margin-bottom: 10px;
  line-height: 20px;
  letter-spacing: -0.01em;
`;

const InputWrapper = styled.div<InputProps>`
  display: flex;
  height: 46px;
  padding: 0px 16px 0px 17.67px;
  align-items:center;
  gap: 12px;
  border-radius: 8px 8px 8px 8px;
  background-color: ${Colors.SurfaceTertiary};
  right: 5px;
  border: 1px solid ${Colors.SecondaryStroke};
  border-color:${(props) => props.validationError? Colors.ErrorRed:""};
`;
const ResendCode = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 0px 14px;
  gap: 8px;
  height: 48px;
  background: #292929;
  border-radius: 8px;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  font-weight: 400;
  font-family: inter;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.HeadingSecondary};

  .newSvg {
    margin-left: auto;
    cursor: pointer;
  }
`;
type PhoneCodeProps = {
  event?: IEventGraphQL;
  season?: ISeasonGraphQL;
};

const PhoneCode: React.FC<PhoneCodeProps> = ({ event, season }) => {
  /** State **/
  const [resetCode, setResetCode] = React.useState(false);
  const [error, setError] = React.useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(0);


  /** State **/
  const { app, user } = useSelector((state: PurchasePortalState) => state);
  const {
    createUserParams: { email },
  } = user;
  const {errors } = app;
  const PromoCodeLimitError = errors[ErrorKeyEnum.PromoCodeLimitError];

  const [code, setCode] = useState([]);

  /** Actions **/
  const dispatch = useDispatch();
  const setPhoneVerificationToken = (phoneVerificationToken: string) =>
    dispatch(UserActions.setPhoneVerificationToken(phoneVerificationToken));
  const navigateForward = () => dispatch(AppActions.navigateForward());
  const seasonNavigateForward = () =>
    dispatch(AppActions.seasonNavigateForward());
  /** GraphQL **/
  const [sendUserPhoneAuthentication] = useMutation(
    SEND_USER_PHONE_AUTHENTICATION,
    {
      variables: {
        email,
      },
      onError: (error) => setError(getErrorMessage(error)),
      onCompleted(data) {
        data.sendUserPhoneAuthentication == true
          ? dispatch(
              AppActions.showNotification(
                "code sent sucessfully",
                AppNotificationTypeEnum.Success
              )
            )
          : "";
      },
    }
  );
  const handleClick = () => {
    sendUserPhoneAuthentication();
    setIsDisabled(true);
    setTimer(1);
    setResetCode(true);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isDisabled && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsDisabled(false);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer, isDisabled]);

  /** Effects **/
  React.useEffect(() => {
    sendUserPhoneAuthentication();
  }, []);

  /** Render **/
  return (
    <Container>
      <Content>
        <Text>Security Code</Text>
        <InputWrapper validationError={errors.Global}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3337 9.16663V6.83329C18.3337 5.89987 18.3337 5.43316 18.152 5.07664C17.9922 4.76304 17.7372 4.50807 17.4236 4.34828C17.0671 4.16663 16.6004 4.16663 15.667 4.16663H4.33366C3.40024 4.16663 2.93353 4.16663 2.57701 4.34828C2.2634 4.50807 2.00844 4.76304 1.84865 5.07664C1.66699 5.43316 1.66699 5.89987 1.66699 6.83329V9.83329C1.66699 10.7667 1.66699 11.2334 1.84865 11.5899C2.00844 11.9035 2.2634 12.1585 2.57701 12.3183C2.93353 12.5 3.40024 12.5 4.33366 12.5H9.16699M10.0003 8.33329H10.0045M14.167 8.33329H14.1712M5.83366 8.33329H5.83783M16.042 14.1666V12.7083C16.042 11.9029 15.3891 11.25 14.5837 11.25C13.7782 11.25 13.1253 11.9029 13.1253 12.7083V14.1666M10.2087 8.33329C10.2087 8.44835 10.1154 8.54163 10.0003 8.54163C9.88527 8.54163 9.79199 8.44835 9.79199 8.33329C9.79199 8.21823 9.88527 8.12496 10.0003 8.12496C10.1154 8.12496 10.2087 8.21823 10.2087 8.33329ZM14.3753 8.33329C14.3753 8.44835 14.2821 8.54163 14.167 8.54163C14.0519 8.54163 13.9587 8.44835 13.9587 8.33329C13.9587 8.21823 14.0519 8.12496 14.167 8.12496C14.2821 8.12496 14.3753 8.21823 14.3753 8.33329ZM6.04199 8.33329C6.04199 8.44835 5.94872 8.54163 5.83366 8.54163C5.7186 8.54163 5.62533 8.44835 5.62533 8.33329C5.62533 8.21823 5.7186 8.12496 5.83366 8.12496C5.94872 8.12496 6.04199 8.21823 6.04199 8.33329ZM13.0003 17.5H16.167C16.6337 17.5 16.8671 17.5 17.0453 17.4091C17.2021 17.3292 17.3296 17.2018 17.4095 17.045C17.5003 16.8667 17.5003 16.6333 17.5003 16.1666V15.5C17.5003 15.0332 17.5003 14.7999 17.4095 14.6216C17.3296 14.4648 17.2021 14.3373 17.0453 14.2575C16.8671 14.1666 16.6337 14.1666 16.167 14.1666H13.0003C12.5336 14.1666 12.3003 14.1666 12.122 14.2575C11.9652 14.3373 11.8377 14.4648 11.7578 14.6216C11.667 14.7999 11.667 15.0332 11.667 15.5V16.1666C11.667 16.6333 11.667 16.8667 11.7578 17.045C11.8377 17.2018 11.9652 17.3292 12.122 17.4091C12.3003 17.5 12.5336 17.5 13.0003 17.5Z"
              stroke="#D6D6D6"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <CodeInput
            key={6}
            length={4}
            placeholder={"-"}
            onChange={(e: any) => {
              setCode(e);
              // no
              setResetCode(false);
            }}
            onComplete={(phoneVerificationToken: string) => {
              setPhoneVerificationToken(phoneVerificationToken);
              if (event) {
                navigateForward();
              } else if (season) {
                seasonNavigateForward();
              }
            }}
            resetCode={resetCode}
            color={Colors.HeadingSecondary}
            height={"45px"}
            width={"15px"}
            border={"0px"}
            bgColor={Colors.SurfaceTertiary}
            borderHover={"none"}
            marginRight={"0px"}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            fontFamily={FontsEnum.FontFamily}
          />
        </InputWrapper>
        <Spacer />
        <ResendCode>
          Resend Code
          {/* {timer > 0 && <span>({timer}sec)</span>} */}
          <div
            className="newSvg"
            onClick={() => {
              sendUserPhoneAuthentication();
              setResetCode(true);
            }}
            // onClick={!isDisabled ? handleClick : undefined}
          >
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              // style={{ opacity: isDisabled ? 0.5 : 1 }}
            >
              <path
                d="M7.99984 17.0833C11.9119 17.0833 15.0832 13.912 15.0832 9.99998C15.0832 7.64536 13.9343 5.55909 12.1665 4.27115M8.83317 18.6666L7.1665 17L8.83317 15.3333M7.99984 2.91665C4.08782 2.91665 0.916504 6.08796 0.916504 9.99998C0.916504 12.3546 2.06539 14.4409 3.83317 15.7288M7.1665 4.66665L8.83317 2.99998L7.1665 1.33331"
                stroke="#FCFCFC"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </ResendCode>

        {!error
          ? code?.every((x) => x) &&
            app?.errors?.Global && (
              <Error margin="20px 0px 0px 0px">{app?.errors?.Global}</Error>
            )
          : error && <Error margin="20px 0px 0px 0px">{error}</Error>}
      </Content>
    </Container>
  );
};
export default PhoneCode;
