import React, { Fragment } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import { PurchasePortalState } from "../redux/store";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import { Colors } from "@sellout/ui/build/Colors";
import ScreenHeader from "../components/ScreenHeader";
import { EPurchasePortalModes } from "@sellout/models/.dist/enums/EPurchasePortalModes";
import Button, {
  ButtonIconPosition,
  ButtonTypes,
} from "@sellout/ui/build/components/Button";
import { FadeIn } from "@sellout/ui/build/components/Motion";
import usePrintOrder from "../hooks/usePrintOrder.hook";
import SEND_ORDER_RECEIPT_EMAIL from "@sellout/models/.dist/graphql/mutations/sendOrderReceiptEmail.mutation";
import { useMutation } from "@apollo/client";
import useEventHook from "../hooks/useEvent.hook";
import { ISeasonGraphQL } from "@sellout/models/.dist/interfaces/ISeason";
import { ScreenEnum } from "../redux/reducers/app.reducer";
import { FontsEnum } from "../models/enums/FontsEnum";
import { Icons } from "@sellout/ui";

const Container = styled.div`
  position: relative;
  top: -50px;
  background-color: ${Colors.SurfaceTertiary};
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  padding-bottom: 200px;
`;

const Content = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Emoji = styled.div`
  font-size: 4.8rem;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  color: ${Colors.HeadingSecondary};
  margin-bottom: 10px;
  display: flex;
  white-space: pre-wrap;
  font-family: 'Inter';
  line-height: 140%;
  letter-spacing: -0.08px;
  text-align:center;
`;

const Text = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${Colors.HeadingSecondary};
  margin-bottom: 40px;
  text-align: center;
  line-height: 140%;
  font-family: 'Inter';
  letter-spacing: -0.08px;
`;
const CheckoutText = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${Colors.HeadingSecondary};
  margin-bottom: 20px;
  text-align: center;
  line-height: 140%;
  font-family: 'Inter';
  letter-spacing: -0.08px;
`;
const ButtonContainer = styled(FadeIn)`
  position: absolute;
  bottom: 35px;
  width: calc(100% - 60px);
  padding: 0px 30px 20px;
  background-color: ${Colors.SurfaceTertiary};
`;

const AnchorText = styled.div`
  font-size: 1.8rem;
  font-weight: 500;
  color: ${Colors.Orange};
  font-family: "inter";
  display: inline;
  cursor: pointer;
  padding-left: 5px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

type OrderConfirmedProps = {
  event?: IEventGraphQL;
  season?: ISeasonGraphQL;
};

const OrderConfirmed: React.FC<OrderConfirmedProps> = ({ event, season }) => {
  /** State **/
  const { app, order, user } = useSelector(
    (state: PurchasePortalState) => state
  );
  useEventHook("", true);

  const { mode, isComplimentary, fisrtScreen } = app;
  const { userProfile } = user;
  const { order: confirmedOrder, guestCheckout, guestCheckoutEmail } = order;
  /** Hooks **/
  const customerName = userProfile?.user?.lastName || "Guest";
  const isEvent = event ? "event" : "season";
  const { printOrder, loading: printLoading } = usePrintOrder(
    confirmedOrder?._id as string,
    isEvent,
    customerName as string
  );
  /** Actions **/
  const dispatch = useDispatch();
  const resetApp = () => dispatch(AppActions.resetApp());
  const closeApp = () => {
    dispatch(AppActions.closeApp());
    dispatch(AppActions.setScreen(fisrtScreen));
  };

  const [sendOrderReceiptEmail] = useMutation(SEND_ORDER_RECEIPT_EMAIL, {
    variables: {
      orderId: confirmedOrder?._id,
    },
    onError(error) {
      console.error(error);
    },
    onCompleted(data) {
      dispatch(AppActions.setLoading(false));
    },
  });

  const onEmailReceipt = () => {
    dispatch(AppActions.setScreen(ScreenEnum.UserEmail));
  };

  /** Render **/
  const firstName = userProfile?.user?.firstName ?? null;
  return (
    <Container>
      <ScreenHeader blank={true} />
      <Content>
        {(() => {
          if (mode === EPurchasePortalModes.Checkout) {
            return (
              <Fragment>
                <Emoji>🤘</Emoji>
                <Title>{firstName ? `Thanks, ${firstName}!` : "Thanks!"}</Title>
                <CheckoutText>
                  You're going to {event ? event?.name : season?.name}.
                </CheckoutText>
                <CheckoutText>
                  Check for a confirmation of order {confirmedOrder?._id} in
                  your email inbox and on your phone.
                </CheckoutText>
                <CheckoutText>
                  If you do not receive a confirmation within 5 minutes, click
                  <AnchorText
                    onClick={() => {
                      dispatch(AppActions.setLoading(true));
                      sendOrderReceiptEmail();
                    }}
                  >
                    here
                  </AnchorText>
                </CheckoutText>
                <ButtonContainer>
                  <Button
                    type={ButtonTypes.Next}
                    text="Close Window"
                    onClick={() => closeApp()}
                    bgColor={Colors.InteractiveBGPrimary}
                    textColor={Colors.HeadingSecondary}
                    contentAlign={"space-between"}
                    paddingAround={"0 18px"}
                    textTransform={FontsEnum.TextTransformNone}
                    fontSize={FontsEnum.FontSize16}
                    fontWeight={FontsEnum.FontWeight400}
                    fontFamily={FontsEnum.FontFamily}
                    letterSpacing={FontsEnum.LetterSpacing}
                    icon={Icons.RightArrowRegular}
                    iconSize={20}
                    iconPosition={ButtonIconPosition.Right}
                    marginOnButtonText={true}
                  />
                </ButtonContainer>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Emoji>🤘</Emoji>
                <Title>Order #{confirmedOrder?._id} Confirmed</Title>
                {!guestCheckout && (
                  <>
                    {" "}
                    <Text>
                      The customer should have just received a text message
                      confirmation of their ticket purchase
                    </Text>
                    <Text>
                      If they did not, check to confirm that their phone number
                      is {userProfile?.user?.phoneNumber}
                    </Text>{" "}
                  </>
                )}
                {guestCheckout && !guestCheckoutEmail && (
                  <>
                    <Text>
                      The customer's order has been completed. If the customer
                      would like a receipt emailed to them, click the Email
                      Receipt button below.
                    </Text>
                  </>
                )}
                {guestCheckout && guestCheckoutEmail && (
                  <>
                    <Text>
                      The customer should have just received an email receipt of
                      their ticket purchase.
                    </Text>
                    <Text>
                      If they did not, check to confirm that their email is{" "}
                      {guestCheckoutEmail}.
                    </Text>
                  </>
                )}
                <ButtonContainer>
                  {guestCheckout && (
                    <Button
                      type={ButtonTypes.Next}
                      text="Email Receipt"
                      onClick={() => onEmailReceipt()}
                      margin="8px 0px 8px"
                      bgColor={Colors.InteractiveBGSecondary}
                      contentAlign={"space-between"}
                      paddingAround={"0 18px"}
                      textTransform={FontsEnum.TextTransformNone}
                      textColor={Colors.HeadingSecondary}
                      fontSize={FontsEnum.FontSize16}
                      fontWeight={FontsEnum.FontWeight400}
                      fontFamily={FontsEnum.FontFamily}
                      letterSpacing={FontsEnum.LetterSpacing}
                      icon={Icons.RightArrowRegular}
                      iconSize={20}
                      iconPosition={ButtonIconPosition.Right}
                      marginOnButtonText={true}
                    />
                  )}
                  {
                    <Button
                      type={ButtonTypes.Next}
                      text="Print Tickets"
                      onClick={() => printOrder()}
                      loading={printLoading}
                      margin="8px 0px 8px"
                      contentAlign={printLoading?"center" :"space-between"}
                      bgColor={Colors.InteractiveBGSecondary}
                      paddingAround={"0 18px"}
                      textTransform={FontsEnum.TextTransformNone}
                      textColor={Colors.HeadingSecondary}
                      fontSize={FontsEnum.FontSize16}
                      fontWeight={FontsEnum.FontWeight400}
                      fontFamily={FontsEnum.FontFamily}
                      letterSpacing={FontsEnum.LetterSpacing}
                      icon={Icons.RightArrowRegular}
                      iconSize={20}
                      iconPosition={ButtonIconPosition.Right}
                      marginOnButtonText={true}
                    />
                  }
                  <Button
                    type={ButtonTypes.Next}
                    text={`${isComplimentary ? "Comp" : "Sell"} More Tickets`}
                    onClick={() => resetApp()}
                    contentAlign={"space-between"}
                    margin="8px 0px 8px"
                    bgColor={Colors.InteractiveBGSecondary}
                    paddingAround={"0 18px"}
                    textTransform={FontsEnum.TextTransformNone}
                    textColor={Colors.HeadingSecondary}
                    fontSize={FontsEnum.FontSize16}
                    fontWeight={FontsEnum.FontWeight400}
                    fontFamily={FontsEnum.FontFamily}
                    letterSpacing={FontsEnum.LetterSpacing}
                    icon={Icons.RightArrowRegular}
                    iconSize={20}
                    iconPosition={ButtonIconPosition.Right}
                    marginOnButtonText={true}
                  />
                </ButtonContainer>
              </Fragment>
            );
          }
        })()}
      </Content>
    </Container>
  );
};

export default OrderConfirmed;
