import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { PurchasePortalState } from "../redux/store";
import {
  EPurchasePortalModes,
  TicketsEnum,
} from "@sellout/models/.dist/enums/EPurchasePortalModes";
import { Colors } from "@sellout/ui/build/Colors";
import Button, {
  ButtonTypes,
  ButtonStates,
  ButtonIconPosition,
} from "@sellout/ui/build/components/Button";
import { Icons } from "@sellout/ui/build/components/Icon";
import useShowNext from "../hooks/useShowNext.hook";
import { FadeIn } from "@sellout/ui/build/components/Motion";
import * as AppActions from "../redux/actions/app.actions";
import { ErrorKeyEnum, ScreenEnum } from "../redux/reducers/app.reducer";
import PaymentUtil from "@sellout/models/.dist/utils/PaymentUtil";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import * as Price from "@sellout/utils/.dist/price";
import { PaymentMethodTypeEnum } from "@sellout/models/.dist/enums/PaymentMethodTypeEnum";
import isSeatingScreen from "../utils/isSeatingScreen";
import { Flex } from "@sellout/ui";
import { useTabletMedia } from "@sellout/ui/build/utils/MediaQuery";
import EventUtil from "@sellout/models/.dist/utils/EventUtil";
import * as OrderActions from "../redux/actions/order.actions";
import * as UserActions from "../redux/actions/user.actions";
import { FontsEnum } from "../models/enums/FontsEnum";

type ContainerProps = {
  isSeating?: boolean;
  height?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 2000;
  height: ${(props) => (props.height ? "97px" : "auto")};
  background-color: ${(props) =>
    props.isSeating ? Colors.SurfaceTertiary : null};
  border-top: ${(props) =>
    props.isSeating ? `1px solid ${Colors.SecondaryStroke}` : null};
`;

type ButtonContainerProps = {
  isSeating?: boolean;
  buttonMobileMedia?:boolean;
};
type SelectedTicketProps = {
  screen?: string;
  mode?: string;
  existingUser?: boolean;
};
type existingUserCheckoutProps = {
  existingUser?: boolean;
};
const ButtonContainer = styled<ButtonContainerProps | any>(FadeIn)`
  width: ${(props) => (props.isSeating ? "350px" : "calc(100% - 48px)")};
  padding: ${(props) => (props.isSeating ? "12px 0" : "24px")};
  background-color: ${(props) =>
    props.isSeating ? null : Colors.SecondarySurface};
  display: flex;
  border-top: 1px solid
    ${(props) => (props.isSeating ? null : Colors.SecondaryStroke)};
  justify-content:${(props) => {
    if (props.buttonMobileMedia) return "center";
    return "flex-end";
  }}; 
`;
const Tag = styled.div`
  font-size: 16px;
  color: ${Colors.HeadingPrimary};
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 90%;
`;
const CheckoutInfo = styled.div<existingUserCheckoutProps>`
  position: relative;
  background-color: ${Colors.SecondarySurface};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-top: ${(props) => (props.existingUser ? "none" : `1px solid ${Colors.SecondaryStroke}`)};
  padding-top: ${(props) => (props.existingUser ? "0px" : null)};
`;

const Logo = styled.img`
  height: 22px;
  width: auto;
`;

const ItemCount = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${Colors.HeadingSecondary};
  margin-bottom: 4px;
  width: 120px;
  font-family: inter;
`;

const TotalText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.HeadingSecondary};
  width: 0px;
`;

const Total = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Colors.Grey6};
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey2};
  padding: 7px 10px;
  border-radius: 10px;
`;

export const SelectedTicketAddOns = styled.div<SelectedTicketProps>`
  color: ${Colors.HeadingSecondary};
  position: absolute;
  bottom: ${(props) => {
    if (props.existingUser) {
      return "192px";
    }
    if (props.screen === ScreenEnum.CashPayment) {
      return "102px";
    }
    if (props.screen === ScreenEnum.AddPayment) {
      return "103px";
    }
    return "120px";
  }};
  left: 50%;
  transform: translateX(-50%);
  background-color: ${Colors.InteractiveBGPrimary};
  // box-shadow: 0px 0px 23.6px 13px #181818a6;
  padding: 5.5px 16px;
  border-radius: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80%;
  font-family: ${FontsEnum.FontFamily};
  font-weight: ${FontsEnum.FontWeight400};
  font-size: ${FontsEnum.FontSize14};
  letter-spacing: ${FontsEnum.LetterSpacing8};
`;

type ControlsProps = {
  event: Required<IEventGraphQL>;
};

const Controls: React.FC<ControlsProps> = ({ event }) => {
  /** Hooks **/
  const showNext = useShowNext(event);
  const isTablet = useTabletMedia();

  /** State **/
  const { app, order, user } = useSelector(
    (state: PurchasePortalState) => state
  );
  const initialState = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    __typename: "User",
    _id: "",
  };
  const { mode, isComplimentary, screen, errors, waitList } = app;

  const {
    guestCheckout,
    appliedDiscount,
    createOrderParams: { tickets, upgrades, paymentMethodType, userId },
  } = order;

  const {
    createUserParams: { phoneNumber },
  } = user;

  const isGuest = guestCheckout && screen === ScreenEnum.CustomerPhoneNumber;
  const isBoxOffice = mode === EPurchasePortalModes.BoxOffice;
  const isCheckout = mode === EPurchasePortalModes.Checkout;
  const isCashScreen = isBoxOffice && screen === ScreenEnum.CashPayment;
  const visibleUpgrades = event.upgrades.filter((upgrade) => upgrade.visible);

  const allUpgradeVisible: any = event?.upgrades?.filter(
    (upgrade: any) => upgrade.remainingQty > 0
  );
  const allTicketsVisible: any = event?.ticketTypes?.filter(
    (ticket: any) => ticket.remainingQty > 0
  );
  const onlyUpgrade =
    allTicketsVisible.length == 0 && allUpgradeVisible.length !== 0;
  const noUpgradeTicket =
    allTicketsVisible.length == 0 && allUpgradeVisible.length == 0;

  const upgradesOnly =
    event.upgrades.length > 0 &&
    allUpgradeVisible.length > 0 &&
    tickets.length === 0 &&
    screen === ScreenEnum.Tickets &&
    isBoxOffice &&
    visibleUpgrades.length > 0

  /** Actions **/
  const dispatch = useDispatch();

  const setUserExists = (userExists: boolean) =>
    dispatch(UserActions.setUserExists(userExists));

  const onGuestCheckout = () => {
    dispatch(OrderActions.setGuestCheckout(true));
    dispatch(OrderActions.setCreateOrderParams({ userId: "" }));
    dispatch(UserActions.setCreateUserParams(initialState));
    setUserExists(false);
    dispatch(AppActions.navigateForward());
  };

  const navigateForward = () => {
    if (
      screen === ScreenEnum.GuestMembers &&
      event?.organization?.validateMemberId
    ) {
      dispatch(OrderActions.getTicketRestrictionAction());
    } else if (screen === ScreenEnum.CustomerPhoneNumber && guestCheckout) {
      dispatch(OrderActions.setGuestCheckout(false));
    } else {
      upgradesOnly
        ? dispatch(OrderActions.setUpgradesOnly(true))
        : !upgradesOnly &&
          screen === ScreenEnum.Tickets &&
          dispatch(OrderActions.setUpgradesOnly(false));
      dispatch(AppActions.navigateForward());
    }
  };

  let buttonColor = Colors.InteractiveBGPrimary;
  let buttonText = upgradesOnly
    ? "Skip to Add-Ons"
    : (tickets?.length && screen === ScreenEnum.Tickets) ||
      (upgrades?.length && screen === ScreenEnum.Upgrades)
    ? "Add to Order"
    : tickets?.length && screen === ScreenEnum.Upgrades
    ? "Go to Checkout"
    : "Next";
  let existingUserCheckout =
    screen === ScreenEnum.CustomerPhoneNumber && showNext;

  let iconsChanges = false;

  if (buttonText === "Add to Order") {
    iconsChanges = true;
  }
  if (buttonText === "Go to Checkout") {
    buttonColor = Colors.InteractiveBGSecondary;
  }
  if (upgradesOnly) {
    buttonColor = Colors.InteractiveBGSecondary;
  }

  if (screen === ScreenEnum.ConfirmOrder) {
    const isBoxOffice = mode === EPurchasePortalModes.BoxOffice;
    const isCardReader = paymentMethodType === PaymentMethodTypeEnum.CardReader;
    buttonText = isBoxOffice && isCardReader ? "Enter Payment" : "Confirm";
  }
  if (screen === ScreenEnum.CustomerPhoneNumber && isBoxOffice) {
    buttonText = "Continue";
  }
  if (screen === ScreenEnum.LiabilityWaiver) {
    buttonText = "Agree to Waiver";
  }
  if (screen === ScreenEnum.CashPayment) {
    buttonText = "Confirm";
  }
  if (screen === ScreenEnum.UserEmail) {
    buttonText = "Continue";
  }
  let iconToRender: any = Icons.RightArrowRegular;
  let svgIconToRender = "";

  svgIconToRender =
    isCashScreen || screen === ScreenEnum.ConfirmOrder ? "confirmIcon" : "";

  if (isCashScreen || screen === ScreenEnum.ConfirmOrder) {
    iconToRender = "";
  }
  /** Render **/
  const totalParams = {
    tickets,
    upgrades,
    fees: event.fees,
    paymentMethodType,
    promotions: appliedDiscount,
  };

  const guestFeeForPromoter =
    PaymentUtil.calculatePaymentTotal(totalParams).guestFeeForPromoter || 0;
  const guestFeeForSellout =
    PaymentUtil.calculatePaymentTotal(totalParams).guestFeeForSellout || 0;
  const ticketUpgradeGuestFeeTotal =
    PaymentUtil.calculatePaymentTotal(totalParams).subTotal +
      guestFeeForPromoter +
      guestFeeForSellout || 0;

  const total =
    screen === ScreenEnum.ConfirmOrder ||
    screen === ScreenEnum.CashPayment ||
    screen === ScreenEnum.PaymentMethod ||
    screen === ScreenEnum.AddPayment ||
    screen === ScreenEnum.SelectPayment
      ? PaymentUtil.calculatePaymentTotal(totalParams).total || 0
      : ticketUpgradeGuestFeeTotal;

  const errorMsg: any =
    errors[ErrorKeyEnum.ConFirmOrderError] ||
    errors[ErrorKeyEnum.PromoCodeLimitError] ||
    errors[ErrorKeyEnum.PaymentCardError] ||
    errors[ErrorKeyEnum.UserProfileError] ||
    errors[ErrorKeyEnum.Tickets] ||
    errors[ErrorKeyEnum.UserEmail];

  if (isSeatingScreen(screen, event)) {
    return (
      <Container isSeating={true}>
        <Flex align="center" justify="space-between" margin="0 16px 0">
          <Flex direction="column">
            {isBoxOffice && allTicketsVisible.length !== 0 && (
              <ItemCount>
                {tickets.length} Seat{tickets.length === 1 ? "" : "s"} Selected
              </ItemCount>
            )}
            {isCheckout && (
              <ItemCount>
                {tickets.length} Seat{tickets.length === 1 ? "" : "s"} Selected
              </ItemCount>
            )}
            <TotalText>
              {(() => {
                if (isComplimentary) {
                  if (isBoxOffice && allTicketsVisible.length == 0) {
                    return false;
                  } else {
                    return <Tag>{"Comp"}</Tag>;
                  }
                } else if (EventUtil.isRSVP(event)) {
                  return <Tag>{"RSVP"}</Tag>;
                } else if (isBoxOffice && allTicketsVisible.length == 0) {
                  return false;
                } else {
                  return `$${Price.output(total, true)}`;
                }
              })()}
            </TotalText>
          </Flex>
          {(isBoxOffice && onlyUpgrade && waitList) ||
          (isBoxOffice && noUpgradeTicket) ? (
            ""
          ) : (
            <ButtonContainer isSeating={true} buttonMobileMedia={!isTablet}>
              <Button
                type={!isTablet ? ButtonTypes.Regular : ButtonTypes.Next}
                state={showNext ? ButtonStates.Active : ButtonStates.Disabled}
                icon={
                  !isTablet
                    ? Icons.LongRightArrow
                    : iconsChanges
                    ? Icons.PlusRegular
                    : Icons.RightArrowRegular
                }
                text={!isTablet ? undefined : buttonText}
                onClick={() => navigateForward()}
                bgColor={buttonColor}
                textTransform={FontsEnum.TextTransformNone}
                textColor={Colors.HeadingSecondary}
                fontSize={FontsEnum.FontSize16}
                fontWeight={FontsEnum.FontWeight400}
                fontFamily={FontsEnum.FontFamily}
                letterSpacing={FontsEnum.LetterSpacing}
                disabledbuttonTheme={true}
                paddingAround={"0 18px"}
                contentAlign={"space-between"}
                iconSize={18}
                iconPosition={ButtonIconPosition.Right}
                marginOnButtonText={true}
              />
            </ButtonContainer>
          )}
        </Flex>
      </Container>
    );
  }

  if (screen === ScreenEnum.OrderConfirmed) {
    return null;
  }
  let heightSkip = screen === ScreenEnum.Tickets && upgradesOnly;
  return (
    <Container height={heightSkip}>
      {screen === ScreenEnum.GuestMembers ||
      screen === ScreenEnum.Tickets ||
      screen === ScreenEnum.AddPayment
        ? showNext && (
            <ButtonContainer>
              <Button
                type={ButtonTypes.Next}
                text={buttonText}
                onClick={() => navigateForward()}
                bgColor={buttonColor}
                paddingAround={"0 18px"}
                contentAlign={"space-between"}
                icon={
                  iconsChanges ? Icons.PlusRegular : Icons.RightArrowRegular
                }
                iconSize={18}
                iconPosition={ButtonIconPosition.Right}
                textTransform={FontsEnum.TextTransformNone}
                textColor={Colors.HeadingSecondary}
                fontSize={FontsEnum.FontSize16}
                fontWeight={FontsEnum.FontWeight400}
                fontFamily={FontsEnum.FontFamily}
                letterSpacing={FontsEnum.LetterSpacing}
                marginOnButtonText={true}
              />
            </ButtonContainer>
          )
        : showNext &&
          !errorMsg &&
          !isGuest && (
            <ButtonContainer>
              <Button
                type={ButtonTypes.Next}
                text={buttonText}
                onClick={() => navigateForward()}
                bgColor={buttonColor}
                paddingAround={"0 18px"}
                contentAlign={"space-between"}
                icon={iconsChanges ? Icons.PlusRegular : iconToRender}
                iconPosition={ButtonIconPosition.Right}
                iconSize={18}
                textTransform={FontsEnum.TextTransformNone}
                textColor={Colors.HeadingSecondary}
                fontSize={FontsEnum.FontSize16}
                fontWeight={FontsEnum.FontWeight400}
                fontFamily={FontsEnum.FontFamily}
                letterSpacing={FontsEnum.LetterSpacing}
                svgIconName={svgIconToRender}
                svgWidth={"23.33px"}
                svgHeight={"23.33px"}
                marginOnButtonText={true}
              />
            </ButtonContainer>
          )}
      {isBoxOffice && noUpgradeTicket ? (
        false
      ) : (
        <>
          {screen === ScreenEnum.CustomerPhoneNumber && (
            <CheckoutInfo existingUser={existingUserCheckout}>
              <Button
                type={ButtonTypes.Next}
                state={
                  phoneNumber.length <= 1
                    ? ButtonStates.Active
                    : ButtonStates.Disabled
                }
                text={"Continue as Guest"}
                onClick={() => onGuestCheckout()}
                bgColor={Colors.InteractiveBGSecondary}
                paddingAround={"0 18px"}
                contentAlign={FontsEnum.ContentAlign}
                textTransform={FontsEnum.TextTransformNone}
                textColor={Colors.HeadingSecondary}
                fontSize={FontsEnum.FontSize16}
                fontWeight={FontsEnum.FontWeight400}
                fontFamily={FontsEnum.FontFamily}
                letterSpacing={FontsEnum.LetterSpacing}
                disabledbuttonTheme={true}
                svgIconName={"guestCheckoutIcon"}
                svgWidth={"21px"}
                svgHeight={"19.83px"}
                marginOnButtonText={true}
              />
            </CheckoutInfo>
          )}
        </>
      )}
      {/* {(() => {
            if (screen === ScreenEnum.EventUnavailable) {
              return null;
            }
            if (isComplimentary) {
              return (
                <Total>
                  Comp
                  <Icon
                    icon={Icons.GiftLight}
                    color={Colors.Grey2}
                    size={14}
                    margin="0 0 0 7px"
                    top="1px"
                  />
                </Total>
              );
            } else if (EventUtil.isRSVP(event)) {
              return (
                <Total>
                  RSVP
                  <Icon
                    icon={Icons.ClipboardLight}
                    color={Colors.Grey2}
                    size={14}
                    margin="0 0 0 7px"
                    top="1px"
                  />
                </Total>
              );
            } else {
              if (isBoxOffice && noUpgradeTicket) {
                return false;
              } else {
                return (
                  <Total>
                    ${Price.output(total, true)}
                    <Icon
                      icon={Icons.CartLight}
                      color={Colors.Grey2}
                      size={14}
                      margin="0 0 0 7px"
                      top="1px"
                    />
                  </Total>
                );
              }
            }
          })()} */}
      {/* </CheckoutInfo> */}
      {/* )} */}

      {(screen === ScreenEnum.Tickets || screen === ScreenEnum.Upgrades) &&
        (tickets?.length || upgrades?.length) && (
          <SelectedTicketAddOns>
            {tickets?.length ? `${tickets?.length} ${TicketsEnum.Tickets}` : ""}
            {tickets?.length && upgrades?.length ? ` & ` : ""}
            {upgrades?.length
              ? `${upgrades?.length} ${TicketsEnum.AddOns}`
              : ""}
          </SelectedTicketAddOns>
        )}

      {(screen === ScreenEnum.AddPayment ||
        screen === ScreenEnum.CashPayment ||
        screen === ScreenEnum.PaymentMethod ||
        screen === ScreenEnum.CustomerPhoneNumber ||
        screen === ScreenEnum.SelectPayment) && (
        <SelectedTicketAddOns
          screen={screen}
          existingUser={existingUserCheckout}
        >
          <div>
            Total: <span>${Price.output(total, true)}</span>
          </div>
        </SelectedTicketAddOns>
      )}
    </Container>
  );
};

export default Controls;
