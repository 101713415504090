import React from 'react';
import styled from 'styled-components';
import { Colors } from "../Colors";
import UserImage from './UserImage';

export const Container = styled.div`
  display: flex;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Name = styled.div<NameProps>`
  font-size: 1.4rem;
  color: ${(props) => props.color || Colors.Grey1};
  font-weight: 600;
  font-family:${(props) => props.fontFamily};
`;

export const Email = styled.div<EmailProps>`
  font-size: 1.2rem;
  color: ${(props) => props.color || Colors.Grey1};
  font-family:${(props) => props.fontFamily};
`;

const PhoneNumber = styled.div<PhoneNumberProps>`
  font-size: 1.2rem;
  color: ${(props) => props.color || Colors.Grey1};
  font-family:${(props) => props.fontFamily};
`;

export enum UserInfoSizeEnum {
  Regular = 'Regular',
  Large = 'Large',
};

type UserInfoPropTypes = {
  user?: any;
  size?: UserInfoSizeEnum;
  invert?: boolean;
  nameColor?:string;
  fontFamily?:string;
};
type NameProps = {
  fontFamily?:string;
};
type EmailProps = {
  fontFamily?:string;
};
type PhoneNumberProps = {
  fontFamily?:string;
};
const UserInfo: React.FC<UserInfoPropTypes> = ({
  user = {},
  size = UserInfoSizeEnum.Regular,
  invert = false,
  nameColor,
  fontFamily
}) => {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    userProfile: {
      imageUrl = '',
    } = {},
  } = user;
  const isLarge = size === UserInfoSizeEnum.Large;
  const UserName = React.memo(() => <Name color={nameColor} fontFamily={fontFamily}>{firstName}&nbsp;{lastName}</Name>);
  const UserEmail = React.memo(() => <Email color={nameColor} fontFamily={fontFamily}>{email}</Email>);
  const UserPhoneNumber = React.memo(() => <PhoneNumber fontFamily={fontFamily} color={nameColor}>{phoneNumber} </PhoneNumber>);
  const height = isLarge ? '50px' : '36px';
  return (
    <Container>
      <UserImage
        imageUrl={imageUrl}
        height={height}
        size={isLarge ? "1.8rem" : "1.4rem"}
        firstName={firstName}
        lastName={lastName}
        invert={invert}
      />
      <Details>
        {firstName && lastName && <UserName />}
        {email && <UserEmail />}
        {isLarge && phoneNumber && <UserPhoneNumber />}
      </Details>
    </Container>
  );
}

export default UserInfo;

