import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import Icon, { Icons } from "@sellout/ui/build/components/Icon";
import { Colors } from "@sellout/ui/build/Colors";
import { PurchasePortalState } from "../redux/store";
import { ErrorKeyEnum, ScreenEnum } from "../redux/reducers/app.reducer";

const Container = styled.div`
  background: ${Colors.InteractiveBGSecondary};
  color: ${Colors.HeadingSecondary};
  padding: 10px;
  font-size: 14px;
  display: flex;
  gap: 8px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-weight: normal;
  width: fit-content;
`;

type BackButtonProps = {};

const BackButton: React.FC<BackButtonProps> = () => {
  /** Actions **/

  const {
    app: { eventId, seasonId, waitList, screen },
  } = useSelector((state: PurchasePortalState) => state);

  const setError = (errorMsg: string) =>
    dispatch(AppActions.setError(ErrorKeyEnum.PaymentCardError, errorMsg));
  const dispatch = useDispatch();
  const navigateBackward = () => {
    if (eventId && !waitList) {
      dispatch(AppActions.navigateBackward());
    }
    if (waitList) {
      dispatch(AppActions.setWaitList(false));
    } else if (seasonId) {
      dispatch(AppActions.seasonNavigateBackward());
    }
    setError("");
  };
  let buttonText = "Back";

  if (screen === ScreenEnum.PhoneCode) {
    buttonText = "Edit Email";
  }
  if (screen === ScreenEnum.AddPayment) {
    buttonText = "Cancel";
  }

  /** Render **/
  return (
    <Container onClick={() => navigateBackward()}>
      <Icon
        icon={Icons.BackArrow}
        color={Colors.HeadingSecondary}
        size={13}
      />
      {buttonText}
    </Container>
  );
};

export default BackButton;
