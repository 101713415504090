import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import * as Price from "@sellout/utils/.dist/price";
import { Colors } from "@sellout/ui/build/Colors";
import * as Time from "@sellout/utils/.dist/time";
import { media } from "@sellout/ui/build/utils/MediaQuery";
import Icon, { Icons } from "@sellout/ui/build/components/Icon";
import { ScreenEnum } from "../redux/reducers/app.reducer";
import SeasonUtil from "@sellout/models/.dist/utils/SeasonUtil";
import { PurchasePortalState } from "../redux/store";
import { SeatsioClient } from "seatsio";
import useSeatingKeysHook from "../hooks/useSeatingKeys.hook";
import { ISeasonGraphQL } from "@sellout/models/.dist/interfaces/ISeason";
import EventUtil from "@sellout/models/.dist/utils/EventUtil";
import { Label } from "@sellout/ui";
import { HeaderText, Heading, Margin, OrderPrice } from "./EventInfo";
import BackButton from "./BackButton";
import PaymentUtil from "@sellout/models/.dist/utils/PaymentUtil";
import { FontsEnum } from "../models/enums/FontsEnum";


type ContainerProps = {
  image?: string;
};

const Container = styled.div<ContainerProps>`
  padding: 24px;
  background: ${Colors.SecondarySurface};
  border-bottom: 1px solid ${Colors.SecondaryStroke};
`;
const Image = styled.div<ContainerProps>`
  min-width: 80px;
  height: 50px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  border-radius: 8px;
`;
const Info = styled.div`
`;

const Name = styled.div`
  color: ${Colors.HeadingSecondary};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  font-family: "Inter";
  text-align: left;
`;

const InfoText = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Colors.TextSupportSecondary};
`;

const Exit = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  z-index: 10000;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const Text = styled.div`
  font-size: ${FontsEnum.FontSize16};
  line-height: 6px;
  font-weight: ${FontsEnum.FontWeight500};
  color: ${Colors.Red};
  align-items: right;
  font-family: ${FontsEnum.FontFamily};
`;

const Timer = styled.div`
  position: absolute;
  top: 70px;
  right: 9px;
  ${media.handheld`
  top: 52px;
  `};
`;

type SeasonInfoProps = {
  season: Required<ISeasonGraphQL>;
};

const SeasonInfo: React.FC<SeasonInfoProps> = ({ season }) => {
  /** State **/

  const [timer, setTimer] = React.useState(0 as number);
  const [untilAnnounced, setUntilAnnounced] = React.useState(
    Time.formattedCountDown(Time.now(), timer as number)
  );
  const [session, setSession] = React.useState(false as boolean);
  const { app, user } = useSelector((state: PurchasePortalState) => state);
  const {
    createUserParams: { email },
  } = user;

  const { venue } = season as any;
  const address = venue?.address;
  const venueLocation = `${venue?.name || ""}${
    address ? `, ${address.city}, ${address.state}` : ""
  }`;
  let timezone = address.timezone != "" ? address.timezone : "America/Denver";
  //
  //   const startsAt = moment(Time.date(season?.schedule?.startsAt))
  //     .tz(timezone)
  //     .format("ddd, MMM D [at] h:mmA");

  const isOnSale = SeasonUtil.isOnSale(season);
  const { fisrtScreen, waitList, loading } = app;
  /** State **/
  const dispatch = useDispatch();
  const {
    app: { mode, SeatingPlanTimer, screen },
    order,
  } = useSelector((state: PurchasePortalState) => state);
  const {
    appliedDiscount,
    createOrderParams: { tickets,upgrades, paymentMethodType  },
  } = order;

  const totalParams = {
    tickets,
    upgrades,
    fees: season.fees,
    paymentMethodType,
    promotions: appliedDiscount,
  };
  const { isComplimentary } = app;
  const ticketTotal = tickets?.reduce(
    (cur, ticket) => cur + parseFloat(ticket.values as string),
    0
  );
  const total =
    EventUtil.isRSVP(season as any) || isComplimentary
      ? ticketTotal
      : PaymentUtil.calculatePaymentTotal(totalParams).total;
  //////////////// For seating plan seats release /////////////
  let seats = [] as any;
  tickets && tickets.map((a) => a.seat && seats.push(a.seat));
  const { secretKey } = useSeatingKeysHook(season?.orgId as any);

  const closeApp = () => {
    dispatch(AppActions.closeApp());
    dispatch(AppActions.setScreen(fisrtScreen));
    if (seats.length > 0 && screen !== ScreenEnum.OrderConfirmed) {
      seatsRelease();
      const SeatingPlanTimer = 0;
      dispatch(AppActions.setSeatTimer({ SeatingPlanTimer }));
    }
  };
  const setScreen = (screen: ScreenEnum) => {
    dispatch(AppActions.setScreen(screen));
  };
  

  const seatsRelease = async () => {
    const seatingId = EventUtil.seatingId(season);
    let seatsIOClient = new SeatsioClient(secretKey);
    return await seatsIOClient.events.release(seatingId, seats);
  };

  React.useEffect(() => {
    setTimer(SeatingPlanTimer);
    const interval = setInterval(() => {
      setUntilAnnounced(
        Time.formattedCountDown(Time.now(), SeatingPlanTimer as number)
      );
    }, 1000);
    return () => clearInterval(interval);
  }, [SeatingPlanTimer]);

  React.useEffect(() => {
    const timers = Time.now();
    if (SeatingPlanTimer === timers) {
      const SeatingPlanTimer = 1;
      dispatch(AppActions.setSeatTimer({ SeatingPlanTimer }));
      setSession(true);
    }
  }, [untilAnnounced]);

  const firstDay =
    season?.schedule &&
    Time.format(
      season?.schedule?.startsAt,
      "ddd, MMM DD, YYYY [at] h:mma",
      timezone
    );
  const lastDay =
    season?.schedule &&
    Time.format(
      season?.schedule?.endsAt,
      "ddd, MMM DD, YYYY [at] h:mma",
      timezone
    );
  const screenContent = {
    Tickets: {
      heading: "TICKETS",
      showImage: true,
      showParagraph: false,
      paragraphText: "",
    },
    Upgrades: {
      heading: "ADD-ONS",
      showImage: true,
      showParagraph: false,
      paragraphText: "",
    },
    "User Email": {
      heading: "CHECKOUT",
      showImage: false,
      showParagraph: true,
      paragraphText:
        "Enter your email address to link this purchase to an existing account or checkout as guest and we’ll email your tickets to you.",
    },
    "Phone Code": {
      heading: "WE EMAILED YOU",
      showImage: false,
      showParagraph: true,
      paragraphText: `We sent an email to ${email}<br /><br />If this email is associated with an account, you will receive an email.`,
    },
    "Add Payment": {
      heading: "MANUAL CARD ENTRY",
      showImage: false,
      showParagraph: true,
      paragraphText: `Select a payment method, and enter the card details below:`,
    },
    "Cash Payment": {
      heading: "CASH PAYMENT",
      showImage: false,
      showParagraph: true,
      paragraphText: `Enter the amount of cash the customer paid to record both the payment received and the change due to the customer.`,
    },
    "Custom Fields": {
      heading: "SURVEY QUESTIONS",
      showImage: true,
      showParagraph: false,
      paragraphText: "",
    },
    "Liability Waiver": {
      heading: "LiABILITY WAVIER",
      showImage: true,
      showParagraph: false,
      paragraphText: "",
    },
    "Payment Method": {
      heading: "SELECT PAYMENT METHOD",
      showImage: false,
      showParagraph: true,
      paragraphText: "Choose a payment method from the options below:",
    },
  };
  const currentScreenConfig = screenContent[screen] || {
    heading: "",
    showImage: true,
    showParagraph: false,
    paragraphText: false,
  };
  /** Render **/
  return (
    <Container>
      {screen !== ScreenEnum.Tickets &&
        screen !== ScreenEnum.EventUnavailable &&
        !waitList &&
        !(screen === ScreenEnum.GuestMembers && loading) && <BackButton />}
      <Exit>
        <Icon
          icon={Icons.CancelCircle}
          color={Colors.HeadingSecondary}
          onClick={() => closeApp()}
          size={18}
        />
      </Exit>
      <Heading>{currentScreenConfig.heading}</Heading>
      <Flex>
        {currentScreenConfig.showImage && (
          <>
            <Image image={season?.posterImageUrl} />
            <Info>
              <Name>{season?.name || "Unnamed Season"}</Name>
              <InfoText>{firstDay + " - " + lastDay}</InfoText>
            </Info>
          </>
        )}
        {currentScreenConfig.showParagraph && (
          <HeaderText
            dangerouslySetInnerHTML={{
              __html: currentScreenConfig.paragraphText,
            }}
          />
        )}

        <Timer>
          {!session && untilAnnounced.length < 11 && (
            <Text>{untilAnnounced.slice(3)}</Text>
          )}
        </Timer>
      </Flex>
      <Margin />
      {screen === ScreenEnum.ConfirmOrder && (
        <>
          <Label
            text={"Your Order"}
            fontFamily="inter"
            fontSize={"16px"}
            labelColor={Colors.HeadingSecondary}
            fontWeight={"400"}
            margin={"5px 2px 0px"}
          ></Label>
          <OrderPrice>${Price.output(total, true)}</OrderPrice>
        </>
      )}
      <Margin />
    </Container>
  );
};

export default SeasonInfo;
