import React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const DiscountIcon: React.FC<IconProps> = ({
  width,
  height,
  ...props
}) => (
<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M2.08389 10.8334H7.08389M12.9172 10.8334H17.9172M10.0006 5.83341V17.5001M10.0006 5.83341C11.1512 5.83341 12.0839 4.90067 12.0839 3.75008M10.0006 5.83341C8.84996 5.83341 7.91722 4.90068 7.91722 3.75008M3.33389 17.5001L16.6672 17.5001M3.33389 3.75009L7.91722 3.75008M7.91722 3.75008C7.91722 2.59949 8.84996 1.66675 10.0006 1.66675C11.1512 1.66675 12.0839 2.59949 12.0839 3.75008M12.0839 3.75008L16.6672 3.75008M7.40092 11.947C7.06701 13.2256 5.93231 14.1667 4.58389 14.1667C3.23547 14.1667 2.10077 13.2256 1.76686 11.947C1.73958 11.8426 1.72594 11.7903 1.72462 11.5816C1.72381 11.4537 1.77136 11.1587 1.81232 11.0375C1.87915 10.8398 1.95148 10.7282 2.09615 10.505L4.58389 6.66675L7.07163 10.505C7.2163 10.7282 7.28864 10.8398 7.35546 11.0375C7.39642 11.1587 7.44397 11.4537 7.44316 11.5816C7.44185 11.7903 7.42821 11.8426 7.40092 11.947ZM18.2343 11.947C17.9003 13.2256 16.7656 14.1667 15.4172 14.1667C14.0688 14.1667 12.9341 13.2256 12.6002 11.947C12.5729 11.8426 12.5593 11.7903 12.558 11.5816C12.5571 11.4537 12.6047 11.1587 12.6457 11.0375C12.7125 10.8398 12.7848 10.7282 12.9295 10.505L15.4172 6.66675L17.905 10.505C18.0496 10.7282 18.122 10.8398 18.1888 11.0375C18.2298 11.1587 18.2773 11.4537 18.2765 11.5816C18.2752 11.7903 18.2615 11.8426 18.2343 11.947Z" stroke="#424242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);


export const ServiceFeeIcon: React.FC<IconProps> = ({
  width,
  height,
  ...props
}) => (
<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M12.917 12.5001L15.417 10.0001L12.917 7.50008M7.08366 7.50008L4.58366 10.0001L7.08366 12.5001M10.8337 5.83341L9.16699 14.1667M18.3337 10.0001C18.3337 14.6025 14.6027 18.3334 10.0003 18.3334C5.39795 18.3334 1.66699 14.6025 1.66699 10.0001C1.66699 5.39771 5.39795 1.66675 10.0003 1.66675C14.6027 1.66675 18.3337 5.39771 18.3337 10.0001Z" stroke="#424242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export const StripeProcessingFeeIcon: React.FC<IconProps> = ({
  width,
  height,
  ...props
}) => (
<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M11.2749 6.95996C10.7979 7.30004 10.2141 7.50008 9.58366 7.50008C7.97283 7.50008 6.66699 6.19425 6.66699 4.58341C6.66699 2.97258 7.97283 1.66675 9.58366 1.66675C10.6278 1.66675 11.5438 2.21543 12.0591 3.0402M5.00033 16.7394H7.17557C7.45918 16.7394 7.74106 16.7731 8.016 16.8406L10.3144 17.3991C10.8131 17.5206 11.3326 17.5325 11.8366 17.4346L14.3778 16.9402C15.0491 16.8094 15.6666 16.488 16.1506 16.0172L17.9486 14.2682C18.462 13.7695 18.462 12.9604 17.9486 12.461C17.4863 12.0113 16.7543 11.9607 16.2313 12.342L14.1358 13.8708C13.8357 14.0902 13.4706 14.2083 13.095 14.2083H11.0716L12.3595 14.2082C13.0855 14.2082 13.6735 13.6362 13.6735 12.93V12.6744C13.6735 12.088 13.2633 11.5767 12.6787 11.435L10.6908 10.9515C10.3673 10.8731 10.036 10.8334 9.70294 10.8334C8.89894 10.8334 7.44357 11.4991 7.44357 11.4991L5.00033 12.5208M16.667 5.41675C16.667 7.02758 15.3612 8.33342 13.7503 8.33342C12.1395 8.33342 10.8337 7.02758 10.8337 5.41675C10.8337 3.80592 12.1395 2.50008 13.7503 2.50008C15.3612 2.50008 16.667 3.80592 16.667 5.41675ZM1.66699 12.1667L1.66699 17.0001C1.66699 17.4668 1.66699 17.7001 1.75782 17.8784C1.83771 18.0352 1.9652 18.1627 2.122 18.2426C2.30026 18.3334 2.53361 18.3334 3.00033 18.3334H3.66699C4.1337 18.3334 4.36706 18.3334 4.54532 18.2426C4.70212 18.1627 4.8296 18.0352 4.9095 17.8784C5.00033 17.7001 5.00033 17.4668 5.00033 17.0001V12.1667C5.00033 11.7 5.00033 11.4667 4.9095 11.2884C4.8296 11.1316 4.70212 11.0041 4.54532 10.9242C4.36706 10.8334 4.1337 10.8334 3.66699 10.8334L3.00033 10.8334C2.53362 10.8334 2.30026 10.8334 2.122 10.9242C1.9652 11.0041 1.83771 11.1316 1.75782 11.2884C1.66699 11.4667 1.66699 11.7 1.66699 12.1667Z" stroke="#424242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export const SalesTaxFeeIcon: React.FC<IconProps> = ({
  width,
  height,
  ...props
}) => (
<svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M2.08389 10.8334H7.08389M12.9172 10.8334H17.9172M10.0006 5.83341V17.5001M10.0006 5.83341C11.1512 5.83341 12.0839 4.90067 12.0839 3.75008M10.0006 5.83341C8.84996 5.83341 7.91722 4.90068 7.91722 3.75008M3.33389 17.5001L16.6672 17.5001M3.33389 3.75009L7.91722 3.75008M7.91722 3.75008C7.91722 2.59949 8.84996 1.66675 10.0006 1.66675C11.1512 1.66675 12.0839 2.59949 12.0839 3.75008M12.0839 3.75008L16.6672 3.75008M7.40092 11.947C7.06701 13.2256 5.93231 14.1667 4.58389 14.1667C3.23547 14.1667 2.10077 13.2256 1.76686 11.947C1.73958 11.8426 1.72594 11.7903 1.72462 11.5816C1.72381 11.4537 1.77136 11.1587 1.81232 11.0375C1.87915 10.8398 1.95148 10.7282 2.09615 10.505L4.58389 6.66675L7.07163 10.505C7.2163 10.7282 7.28864 10.8398 7.35546 11.0375C7.39642 11.1587 7.44397 11.4537 7.44316 11.5816C7.44185 11.7903 7.42821 11.8426 7.40092 11.947ZM18.2343 11.947C17.9003 13.2256 16.7656 14.1667 15.4172 14.1667C14.0688 14.1667 12.9341 13.2256 12.6002 11.947C12.5729 11.8426 12.5593 11.7903 12.558 11.5816C12.5571 11.4537 12.6047 11.1587 12.6457 11.0375C12.7125 10.8398 12.7848 10.7282 12.9295 10.505L15.4172 6.66675L17.905 10.505C18.0496 10.7282 18.122 10.8398 18.1888 11.0375C18.2298 11.1587 18.2773 11.4537 18.2765 11.5816C18.2752 11.7903 18.2615 11.8426 18.2343 11.947Z" stroke="#424242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export const CashIcon: React.FC<IconProps> = ({
  width,
  height,
  ...props
}) => (
<svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M7.08317 12.2221C7.08317 13.2959 7.95373 14.1665 9.02762 14.1665H10.8332C11.9838 14.1665 12.9165 13.2338 12.9165 12.0832C12.9165 10.9326 11.9838 9.99984 10.8332 9.99984H9.1665C8.01591 9.99984 7.08317 9.0671 7.08317 7.9165C7.08317 6.76591 8.01591 5.83317 9.1665 5.83317H10.9721C12.0459 5.83317 12.9165 6.70373 12.9165 7.77762M9.99984 4.58317V5.83317M9.99984 14.1665V15.4165M18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z" stroke="#D6D6D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export const ConfirmIcon: React.FC<IconProps> = ({
  width,
  height,
  ...props
}) => (
<svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M24.6668 11.9335V13.0068C24.6654 15.5227 23.8507 17.9706 22.3444 19.9856C20.838 22.0006 18.7207 23.4747 16.3081 24.1881C13.8955 24.9014 11.317 24.8157 8.95704 23.9439C6.59712 23.072 4.58226 21.4606 3.21295 19.3501C1.84364 17.2395 1.19325 14.7429 1.35879 12.2325C1.52432 9.72217 2.4969 7.33255 4.13149 5.42009C5.76607 3.50763 7.97508 2.17479 10.429 1.62034C12.883 1.0659 15.4505 1.31957 17.7485 2.34351M24.6668 3.66683L13.0002 15.3452L9.50016 11.8452" stroke="#FCFCFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);

export const GuestCheckoutIcon: React.FC<IconProps> = ({
  width,
  height,
  ...props
}) => (
<svg width={width} height={height} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M1.5 21.3333C4.22509 18.443 7.92485 16.6667 12 16.6667C16.0751 16.6667 19.7749 18.443 22.5 21.3333M17.25 6.75C17.25 9.6495 14.8995 12 12 12C9.1005 12 6.75 9.6495 6.75 6.75C6.75 3.85051 9.1005 1.5 12 1.5C14.8995 1.5 17.25 3.85051 17.25 6.75Z" stroke="#FCFCFC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);












