import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { PurchasePortalState } from "../redux/store";
import {
  EPurchasePortalModes,
  TicketsEnum,
} from "@sellout/models/.dist/enums/EPurchasePortalModes";
import { Colors } from "@sellout/ui/build/Colors";
import Button, {
  ButtonTypes,
  ButtonStates,
  ButtonIconPosition,
} from "@sellout/ui/build/components/Button";
import Icon, { Icons } from "@sellout/ui/build/components/Icon";
import * as Polished from "polished";
import useShowNext from "../hooks/useShowNext.hook";
import { FadeIn } from "@sellout/ui/build/components/Motion";
import * as AppActions from "../redux/actions/app.actions";
import { ErrorKeyEnum, ScreenEnum } from "../redux/reducers/app.reducer";
import PaymentUtil from "@sellout/models/.dist/utils/PaymentUtil";
import { ISeasonGraphQL } from "@sellout/models/.dist/interfaces/ISeason";
import * as Price from "@sellout/utils/.dist/price";
import { PaymentMethodTypeEnum } from "@sellout/models/.dist/enums/PaymentMethodTypeEnum";
import { Flex } from "@sellout/ui";
import { useTabletMedia } from "@sellout/ui/build/utils/MediaQuery";
import * as OrderActions from "../redux/actions/order.actions";
import { FontsEnum } from "../models/enums/FontsEnum";
import { SelectedTicketAddOns } from "./Controls";

type ContainerProps = {
  isSeating?: boolean;
};

const Container = styled.div<ContainerProps>`
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 2000;
  background-color: ${(props) =>
    props.isSeating ? Colors.SurfaceTertiary : null};
    border-top: ${(props) =>
    props.isSeating ? `1px solid ${Colors.SecondaryStroke}` : null};
`;

type ButtonContainerProps = {
  isSeating?: boolean;
  buttonMobileMedia?:boolean;
};

const ButtonContainer = styled<ButtonContainerProps | any>(FadeIn)`
  width: ${(props) => (props.isSeating ? "350px" : "calc(100% - 48px)")};
  padding: ${(props) => (props.isSeating ? "12px 0" : "24px")};
  background-color: ${(props) =>
    props.isSeating ? null : Polished.rgba(Colors.SurfaceTertiary, 1)};
  display: flex;
  border-top: 1px solid ${(props) => (props.isSeating ? null : Colors.SecondaryStroke)};
    justify-content:${(props) => {
    if (props.buttonMobileMedia) return "center";
    return "flex-end";
  }};
`;

const CheckoutInfo = styled.div`
  position: relative;
  background-color: ${Colors.SurfaceTertiary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 24px;
`;

const Logo = styled.img`
  height: 22px;
  width: auto;
`;

const ItemCount = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: ${Colors.HeadingSecondary};
  margin-bottom: 4px;
  font-family: inter;
  width: 120px;
`;

const TotalText = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.HeadingSecondary};
  width: 0px;
`;

const Total = styled.div`
  display: flex;
  align-items: center;
  background-color: ${Colors.Grey6};
  font-size: 1.4rem;
  font-weight: 500;
  color: ${Colors.Grey2};
  padding: 7px 10px;
  border-radius: 10px;
`;
const Tag = styled.div`
  font-size: 16px;
  color: ${Colors.HeadingPrimary};
  font-weight: 500;
  margin-bottom: 5px;
  line-height: 90%;
`;
type SeasonControlsProps = {
  season: Required<ISeasonGraphQL>;
};

const SeasonControls: React.FC<SeasonControlsProps> = ({ season }) => {
  /** Hooks **/
  const showNext = useShowNext(season);
  const isTablet = useTabletMedia();

  /** State **/
  const { app, order } = useSelector((state: PurchasePortalState) => state);
  const { mode, isComplimentary, screen, errors } = app;
  const {
    createOrderParams: { tickets, upgrades, paymentMethodType },
  } = order;
  const isBoxOffice = mode === EPurchasePortalModes.BoxOffice;
  const isCashScreen = isBoxOffice && screen === ScreenEnum.CashPayment;

  /** Actions **/
  const dispatch = useDispatch();

  const navigateForward = () => {
    if (screen === ScreenEnum.GuestMembers) {
      dispatch(OrderActions.getTicketRestrictionAction());
    } else {
      dispatch(AppActions.seasonNavigateForward());
    }
  };

  let buttonColor = Colors.InteractiveBGPrimary;
  let buttonText = screen===ScreenEnum.Tickets?"Add to Order" :"Next";

  if (screen === ScreenEnum.ConfirmOrder) {
    const isCardReader = paymentMethodType === PaymentMethodTypeEnum.CardReader;
    buttonText =
      isBoxOffice && isCardReader ? "Enter Payment" : "Confirm Order";
  }
  let iconsChanges = false
  if (buttonText==="Add to Order"){
    iconsChanges = true
  }
  if (screen === ScreenEnum.CustomerPhoneNumber && isBoxOffice) {
    buttonText = "Continue";
  }
  if (screen === ScreenEnum.LiabilityWaiver) {
    buttonText = "Agree to Waiver";
  }
  if (screen === ScreenEnum.CashPayment) {
    buttonText = "Confirm";
  }
  if (screen === ScreenEnum.UserEmail) {
    buttonText = "Continue";
  }
  let iconToRender:any = Icons.RightArrowRegular
  let svgIconToRender = ""

  svgIconToRender = isCashScreen || screen === ScreenEnum.ConfirmOrder ? "confirmIcon" : "";

  if (isCashScreen || screen === ScreenEnum.ConfirmOrder){
    iconToRender = ""
  }
  /** Render **/
  const totalParams = {
    tickets,
    upgrades,
    fees: season.fees,
    paymentMethodType,
  };
  const ticketUpgradeGuestFeeTotal =
    PaymentUtil.calculatePaymentTotal(totalParams).subTotal || 0;
  const total =
    screen === ScreenEnum.ConfirmOrder ||
    screen === ScreenEnum.CashPayment ||
    screen === ScreenEnum.PaymentMethod ||
    screen === ScreenEnum.AddPayment ||
    screen === ScreenEnum.SelectPayment
      ? PaymentUtil.calculatePaymentTotal(totalParams).total || 0
      : ticketUpgradeGuestFeeTotal;

  const errorMsg: any =
    errors[ErrorKeyEnum.ConFirmOrderError] ||
    errors[ErrorKeyEnum.PromoCodeLimitError] ||
    errors[ErrorKeyEnum.PaymentCardError] ||
    errors[ErrorKeyEnum.UserProfileError];

  const isSeatingScreen = (screen: any, season: any) => {
    return (
      screen === ScreenEnum.Tickets && Boolean(season?.seatingChartKey ?? false)
    );
  };
  if (isSeatingScreen(screen, season)) {
    return (
      <Container isSeating={true}>
        <Flex align="center" justify="space-between" margin="0 16px 0">
          <Flex direction="column">
            <ItemCount>
              {tickets.length} Seat{tickets.length === 1 ? "" : "s"} Selected
            </ItemCount>
            <TotalText>
              {(() => {
                if (isComplimentary) {
                  return <Tag>{"Comp"}</Tag>;
                } else {
                  return `$${Price.output(total, true)}`;
                }
              })()}
            </TotalText>
          </Flex>
          <ButtonContainer isSeating={true} buttonMobileMedia={!isTablet}>
            <Button
              type={!isTablet ? ButtonTypes.Regular : ButtonTypes.Next}
              state={showNext ? ButtonStates.Active : ButtonStates.Disabled}
              icon={!isTablet ? Icons.LongRightArrow : (iconsChanges? Icons.PlusRegular:undefined)}
              text={!isTablet ? undefined : buttonText}
              onClick={() => navigateForward()}
              bgColor={buttonColor}
              textTransform={FontsEnum.TextTransformNone}
              textColor={Colors.HeadingSecondary}
              fontSize={FontsEnum.FontSize16}
              fontWeight={FontsEnum.FontWeight400}
              fontFamily={FontsEnum.FontFamily}
              letterSpacing={FontsEnum.LetterSpacing}
              disabledbuttonTheme={true}
              iconPosition={ButtonIconPosition.Right}
              iconSize={18}
              contentAlign={"space-between"}
              paddingAround={"0 18px"}
              marginOnButtonText={true}

            />
          </ButtonContainer>
        </Flex>
      </Container>
    );
  }

  if (screen === ScreenEnum.OrderConfirmed) {
    return null;
  }

  return (
    <Container>
      {showNext && !errorMsg && (
        <ButtonContainer>
          <Button
            type={ButtonTypes.Next}
            text={buttonText}
            onClick={() => navigateForward()}
            bgColor={buttonColor}
            paddingAround={"0 18px"}
            contentAlign={"space-between"}
            icon={iconsChanges? Icons.PlusRegular:iconToRender}
            iconPosition={ButtonIconPosition.Right}
            iconSize={18}
            textTransform={FontsEnum.TextTransformNone}
            textColor={Colors.HeadingSecondary}
            fontSize={FontsEnum.FontSize16}
            fontWeight={FontsEnum.FontWeight400}
            fontFamily={FontsEnum.FontFamily}
            letterSpacing={FontsEnum.LetterSpacing}
            svgIconName={svgIconToRender}
            svgWidth={"23.33px"}
            svgHeight={"23.33px"}
            marginOnButtonText={true}
          />
        </ButtonContainer>
      )}
      {/* <CheckoutInfo>
        <Icon icon={Icons.HelpLight} color={Colors.Grey3} size={24} />
        {(() => {
          if (screen === ScreenEnum.EventUnavailable) {
            return null;
          }

          if (isComplimentary) {
            return (
              <Total>
                Comp
                <Icon
                  icon={Icons.GiftLight}
                  color={Colors.Grey2}
                  size={14}
                  margin="0 0 0 7px"
                  top="1px"
                />
              </Total>
            );
          } else {
            return (
              <Total>
                ${Price.output(total, true)}
                <Icon
                  icon={Icons.CartLight}
                  color={Colors.Grey2}
                  size={14}
                  margin="0 0 0 7px"
                  top="1px"
                />
              </Total>
            );
          }
        })()}
      </CheckoutInfo> */}
      {(screen === ScreenEnum.Tickets || screen === ScreenEnum.Upgrades) &&
        (tickets?.length || upgrades?.length) && (
          <SelectedTicketAddOns>
            {tickets?.length ? `${tickets?.length} ${TicketsEnum.Tickets}` : ""}
            {tickets?.length && upgrades?.length ? ` & ` : ""}
            {upgrades?.length
              ? `${upgrades?.length} ${TicketsEnum.AddOns}`
              : ""}
          </SelectedTicketAddOns>
        )}

      {(screen === ScreenEnum.AddPayment ||
        screen === ScreenEnum.CashPayment || screen === ScreenEnum.PaymentMethod ||
        screen === ScreenEnum.CustomerPhoneNumber ||
        screen === ScreenEnum.SelectPayment) && (
        <SelectedTicketAddOns screen={screen}>
           <div>
            Total: <span>${Price.output(total, true)}</span>
          </div>
        </SelectedTicketAddOns>
      )}
    </Container>
  );
};

export default SeasonControls;
