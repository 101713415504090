import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import * as OrderActions from "../redux/actions/order.actions";
import ICreateOrderParams from "@sellout/models/.dist/interfaces/ICreateOrderParams";
import { Colors } from "@sellout/ui";
import { PurchasePortalState } from "../redux/store";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import { ISeasonGraphQL } from "@sellout/models/.dist/interfaces/ISeason";
import { PaymentMethodTypeEnum } from "@sellout/models/.dist/enums/PaymentMethodTypeEnum";
import Select from "../components/Select";

const Container = styled.div`
  position: relative;
  top: -50px;
  background-color: ${Colors.SurfaceTertiary};
  border-radius: 15px 15px 0 0;
  overflow: hidden;
`;

const Content = styled.div`
  margin: 22px 0 0;
  padding: 0 24px;
`;
const Scrollcontent = styled.div`
  max-height: 195px;
  overflow-y: auto;
  padding: 0 24px;
`;
const DropdownWrapper = styled.div`
  padding: 0 24px;
`;
type PaymentMethodProps = { event?: IEventGraphQL; season?: ISeasonGraphQL };

const PaymentMethod: React.FC<PaymentMethodProps> = ({ event, season }) => {
  /** Actions **/
  const {
    order: {
      createOrderParams: { paymentMethodType },
    },
  } = useSelector((state: PurchasePortalState) => state);
  const dispatch = useDispatch();
  const setOrder = (params: Partial<ICreateOrderParams>) => {
    dispatch(OrderActions.setCreateOrderParams(params));
    dispatch(AppActions.navigateForward());
  };
  /** Render **/

  const handleOnChange = (selectedValue) => {
    dispatch(
      OrderActions.setCreateOrderParams({
        paymentMethodType: selectedValue,
      })
    );
  };
  const options = [
    {
      text: "Enter a New Card",
      placeholderColor: Colors.HeadingSecondary,
      bgColor: Colors.SecondaryStroke,
      icon: (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.6663 11.6667H2.33301M12.833 16.3333H6.99967M2.33301 9.56668L2.33301 18.4333C2.33301 19.7401 2.33301 20.3935 2.58733 20.8927C2.81103 21.3317 3.16798 21.6887 3.60703 21.9124C4.10616 22.1667 4.75955 22.1667 6.06634 22.1667L21.933 22.1667C23.2398 22.1667 23.8932 22.1667 24.3923 21.9124C24.8314 21.6887 25.1883 21.3317 25.412 20.8927C25.6663 20.3935 25.6663 19.7401 25.6663 18.4333V9.56668C25.6663 8.25989 25.6663 7.60649 25.412 7.10737C25.1883 6.66832 24.8314 6.31137 24.3923 6.08766C23.8932 5.83335 23.2398 5.83335 21.933 5.83334L6.06634 5.83334C4.75955 5.83334 4.10616 5.83334 3.60703 6.08766C3.16799 6.31137 2.81103 6.66832 2.58733 7.10737C2.33301 7.60649 2.33301 8.25989 2.33301 9.56668Z"
            stroke="#FCFCFC"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      onClick: () =>
        setOrder({ paymentMethodType: PaymentMethodTypeEnum.CardEntry }),
    },
    {
      text: "Card Reader",
      placeholderColor: Colors.HeadingSecondary,
      bgColor: Colors.SecondaryStroke,
      icon: (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4997 18.6667L13.9997 15.1667M13.9997 15.1667L17.4997 18.6667M13.9997 15.1667V23.3333M25.6663 10.5H2.33301M6.41634 21H6.06634C4.75955 21 4.10616 21 3.60703 20.7457C3.16799 20.522 2.81103 20.165 2.58733 19.726C2.33301 19.2268 2.33301 18.5734 2.33301 17.2667V8.39999C2.33301 7.0932 2.33301 6.43981 2.58733 5.94068C2.81103 5.50163 3.16799 5.14468 3.60703 4.92097C4.10616 4.66666 4.75955 4.66666 6.06634 4.66666H21.933C23.2398 4.66666 23.8932 4.66666 24.3923 4.92098C24.8314 5.14468 25.1883 5.50164 25.412 5.94068C25.6663 6.43981 25.6663 7.0932 25.6663 8.39999V17.2667C25.6663 18.5734 25.6663 19.2268 25.412 19.726C25.1883 20.165 24.8314 20.522 24.3923 20.7457C23.8932 21 23.2398 21 21.933 21H21.583"
            stroke="#FCFCFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      onClick: () =>
        setOrder({ paymentMethodType: PaymentMethodTypeEnum.CardReader }),
    },
    {
      text: "Cash",
      placeholderColor: Colors.HeadingSecondary,
      bgColor: Colors.SecondaryStroke,
      icon: (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.91634 17.1111C9.91634 18.6146 11.1351 19.8333 12.6386 19.8333H15.1663C16.7772 19.8333 18.083 18.5275 18.083 16.9167C18.083 15.3058 16.7772 14 15.1663 14H12.833C11.2222 14 9.91634 12.6942 9.91634 11.0833C9.91634 9.47251 11.2222 8.16668 12.833 8.16668H15.3608C16.8642 8.16668 18.083 9.38546 18.083 10.8889M13.9997 6.41668V8.16668M13.9997 19.8333V21.5833M25.6663 14C25.6663 20.4433 20.443 25.6667 13.9997 25.6667C7.55635 25.6667 2.33301 20.4433 2.33301 14C2.33301 7.55669 7.55635 2.33334 13.9997 2.33334C20.443 2.33334 25.6663 7.55669 25.6663 14Z"
            stroke="#FCFCFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      onClick: () =>
        setOrder({ paymentMethodType: PaymentMethodTypeEnum.Cash }),
    },
    {
      text: "Check",
      placeholderColor: Colors.HeadingSecondary,
      bgColor: Colors.SecondaryStroke,
      icon: (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.99967 11.6666V16.3333M20.9997 11.6666V16.3333M2.33301 9.56665L2.33301 18.4333C2.33301 19.7401 2.33301 20.3935 2.58733 20.8926C2.81103 21.3317 3.16798 21.6886 3.60703 21.9123C4.10616 22.1666 4.75955 22.1666 6.06634 22.1666L21.933 22.1666C23.2398 22.1666 23.8932 22.1666 24.3923 21.9123C24.8314 21.6886 25.1883 21.3317 25.412 20.8926C25.6663 20.3935 25.6663 19.7401 25.6663 18.4333V9.56665C25.6663 8.25986 25.6663 7.60646 25.412 7.10734C25.1883 6.66829 24.8314 6.31134 24.3923 6.08763C23.8932 5.83331 23.2398 5.83331 21.933 5.83331L6.06634 5.83331C4.75955 5.83331 4.10616 5.83331 3.60703 6.08763C3.16799 6.31134 2.81103 6.66829 2.58733 7.10733C2.33301 7.60646 2.33301 8.25986 2.33301 9.56665ZM16.9163 14C16.9163 15.6108 15.6105 16.9166 13.9997 16.9166C12.3888 16.9166 11.083 15.6108 11.083 14C11.083 12.3891 12.3888 11.0833 13.9997 11.0833C15.6105 11.0833 16.9163 12.3891 16.9163 14Z"
            stroke="#FCFCFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      onClick: () =>
        setOrder({ paymentMethodType: PaymentMethodTypeEnum.Check }),
    },
  ];
  return (
    <Container>
      <Content>
        <Select
          options={options}
          flex={"flex"}
          gap={"10px"}
          direction={"column"}
        />
      </Content>
    </Container>
  );
};

export default PaymentMethod;
