import React, { Fragment } from "react";
import styled from "styled-components";
import { Colors } from "@sellout/ui/build/Colors";
import Icon, { Icons } from "@sellout/ui/build/components/Icon";
import * as Polished from "polished";
import { Flex } from "@sellout/ui";

type OptionMainProps = {
  bgColor: string;
};
const OptionContainer = styled.div<OptionMainProps>`
  height: 56px;
  box-sizing: border-box;
  background: ${(props) =>
    props.bgColor ? Colors.InteractiveBGSecondary : "#FFFFFF"};
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  transition: all 0.1s;

  &:hover {
    cursor: ${(props) => (props.onClick ? "pointer" : null)};
    background: ${Polished.rgba(Colors.Orange, 0.05)};
    border: 1px solid ${Colors.InteractiveBGPrimary};
  }
`;

const IconContainer = styled.div`
  position: relative;
  width: 20px;
  margin-left: 16px;
  display: flex;
  align-items: center;
`;

type TextProps = {
  placeholder: string;
};

type FlexGapProps = {
  flex?: string;
  gap?: string;
  direction?: string;
};

const Text = styled.div<TextProps>`
  color: ${(props) => props.placeholder};
  font-size: 16px;
  margin-left: 12px;
  font-weight: 400;
  line-height: 19.2px;
  font-family: "Inter";
`;

const SelectContainer = styled.div<FlexGapProps>`
  margin: 0;
  display: ${(props) => props.flex};
  gap: ${(props) => props.gap};
  flex-direction: ${(props) => props.direction};
`;
type ClickHandler = (event: React.MouseEvent<HTMLElement>) => void;

type Option = {
  text: string;
  icon: any;
  onClick: ClickHandler;
  bgColor: string;
  placeholderColor: string;
};

type OptionProps = {
  option: Option;
};

const Option: React.FC<OptionProps> = ({
  option: { text, icon, onClick, bgColor, placeholderColor },
}: OptionProps) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <OptionContainer
      bgColor={bgColor}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Flex align="center">
        {/* Render the SVG directly */}
        <div style={{ margin: "1px 0 0 16px" }}>{icon}</div>
        <Text placeholder={placeholderColor}>{text}</Text>
      </Flex>
      {/* {hovered && ( */}
        <Icon
          icon={Icons.RightArrowRegular}
          top="1px"
          margin="0 16px 0 0"
          size="16px"
          color={Colors.HeadingSecondary}
        />
      {/* )} */}
    </OptionContainer>
  );
};

type SelectProps = {
  options: Option[];
  flex?: string;
  gap?: string;
  direction?: string;
};

const Select: React.FC<SelectProps> = ({ options, flex, gap, direction }) => {
  return (
    <SelectContainer flex={flex} gap={gap} direction={direction}>
      {options.map((option, index) => (
        <Option key={index} option={option} />
      ))}
    </SelectContainer>
  );
};

export default Select;
