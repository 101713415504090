import React, { useState } from "react";
import { Button, Colors, Icons, Input, PhoneNumberInput } from "@sellout/ui";
import styled from "styled-components";
import { Container, Content } from "./UserEmail";
import { PhoneNumberInputSizes } from "@sellout/ui/build/components/PhoneNumberInput";
import { useMutation } from "@apollo/client";
import * as Validation from "@sellout/ui/build/utils/Validation";
import { ErrorKeyEnum } from "../redux/reducers/app.reducer";
import * as AppActions from "../redux/actions/app.actions";
import { ButtonIconPosition, ButtonStates, ButtonTypes } from "@sellout/ui/build/components/Button";
import GlobalError from "../components/GlobalError";
import { PurchasePortalState } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import CREATE_WAIT_LIST from "@sellout/models/.dist/graphql/mutations/createWaitList.mutation";
import { getErrorMessage } from "../utils/ErrorUtil";
import Error from "../components/Error";
import { FontsEnum } from "../models/enums/FontsEnum";
import { InputSizes } from "@sellout/ui/build/components/Input";

type SpacerProps = {
  height?: string;
};
export const Spacer = styled.div<SpacerProps>`
  height: ${(props) => props?.height || "20px"};
`;

export const FullContainer = styled(Container)`
  width: 100%;
  box-sizing: border-box;
  height: auto;
  background-color: ${Colors.SurfaceTertiary};
  border-radius: 0px;
`;

export const ButtonContainer = styled.div`
   margin: 0 24px;
`;
const WaitContent = styled(Content)`
  // height: 380px;
  h2 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 600;
    color: #333333;
  }
`;
type EventWaitListProps = {
  event?: IEventGraphQL;
  setUserName?: any;
};
const CreateWaitListInfo: React.FC<EventWaitListProps> = ({
  event,
  setUserName,
}) => {
  const initialInfoWaitList = {
    name: "",
    email: "",
    phoneNumber: "",
  };

  /** State */
  const [infoWaitList, setInfoWaitList] = useState({
    name: "",
    phoneNumber: "",
    email: "",
  });
  const [error, setErrors] = React.useState("");
  const { app } = useSelector((state: PurchasePortalState) => state);
  const { errors } = app;
  const emailError = errors[ErrorKeyEnum.WaitList];
  const phoneNumberError = errors[ErrorKeyEnum.UserPhoneNumber];

  /**actions */
  const dispatch = useDispatch();
  const setError = (key: ErrorKeyEnum, errorMsg: string) =>
    dispatch(AppActions.setError(key, errorMsg));

  const handleInputChange = (
    key: keyof typeof infoWaitList,
    value: string | number
  ) => {
    setInfoWaitList((prevInfo) => ({
      ...prevInfo,
      [key]: value,
    }));
    setUserName(infoWaitList.name);
  };
  const resetForm = () => {
    setInfoWaitList({ ...initialInfoWaitList });
  };

  /** GraphQL **/

  const [handleSubmit, { loading }] = useMutation(CREATE_WAIT_LIST, {
    onCompleted(data) {
      resetForm();
      dispatch(AppActions.SetWaitingInfo(true));
    },
    onError: (error) => setErrors(getErrorMessage(error)),
  });

  return (
    <FullContainer>
      <WaitContent>
        <Input
          autoFocus
          label="Name"
          placeholder="Name"
          value={infoWaitList.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const name = e.currentTarget.value;
            handleInputChange("name", name);
          }}
          size={InputSizes.Large}
          width="100%"
          margin="0px 0px 10px 0px"
          labelColor={`${Colors.HeadingSecondary}`}
          placeholderTextColor={`${Colors.SupportTertiary}`}
          bgColor={`${Colors.SurfaceTertiary}`}
          fontSize={FontsEnum.FontSize16}
          fontWeight={FontsEnum.FontWeight500}
          fontFamily={FontsEnum.FontFamily}
          letterSpacing={FontsEnum.LetterSpacing}
        />
        <Input
          type="email"
          label="Email address"
          placeholder="Email address"
          value={infoWaitList.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const email = e.currentTarget.value;
            const message =
              Validation.email.validate(email)?.error?.message || "";
            handleInputChange("email", email);
            email
              ? setError(ErrorKeyEnum.WaitList, message)
              : setError(ErrorKeyEnum.WaitList, "");
          }}
          size={InputSizes.Large}
          margin="0px 0px 10px 0px"
          validationError={emailError}
          labelColor={`${Colors.HeadingSecondary}`}
          placeholderTextColor={`${Colors.SupportTertiary}`}
          bgColor={`${Colors.SurfaceTertiary}`}
          fontSize={FontsEnum.FontSize16}
          fontWeight={FontsEnum.FontWeight500}
          fontFamily={FontsEnum.FontFamily}
          letterSpacing={FontsEnum.LetterSpacing}
          validationDarkThemeBorderColor={true}
          
        />
        <PhoneNumberInput
          label="Phone number"
          phoneNumberInputSize={PhoneNumberInputSizes.Large}
          value={infoWaitList.phoneNumber}
          onChange={(phoneNumber: number) => {
            const message =
              Validation.phoneNumber.validate(phoneNumber)?.error?.message ||
              "";
            handleInputChange("phoneNumber", phoneNumber);
            setError(ErrorKeyEnum.UserPhoneNumber, message);
            setError(ErrorKeyEnum.UserProfileError, "");
          }}
          validationError={phoneNumberError}
          typedTextColor={Colors.HeadingSecondary}
          labeltextColor={Colors.HeadingSecondary}
          bgColor={Colors.SurfaceTertiary}
          selectedflagbg={Colors.SurfaceTertiary}
          selectedflaghover={Colors.SecondaryStroke}
          countryList={Colors.SurfaceTertiary}
          countryName={Colors.HeadingSecondary}
          highlightColor={Colors.InteractiveBGSecondary}
          searchColor={Colors.SecondaryStroke}
          fontSize={FontsEnum.FontSize16}
          fontWeight={FontsEnum.FontWeight500}
          fontFamily={FontsEnum.FontFamily}
          letterSpacing={FontsEnum.LetterSpacing}
          validationDarkThemeBorderColor={true}
        />
        <GlobalError />
        {error && <Error margin="6px 0px 0px 0px">{error}</Error>}
      </WaitContent>
      <Spacer height="220px"/>
      <ButtonContainer>
        <Button
          type={ButtonTypes.Next}
          text="Submit"
          textColor={Colors.HeadingSecondary}
          state={
            infoWaitList.name === "" ||
            emailError !== "" ||
            phoneNumberError !== ""
              ? ButtonStates.Disabled
              : ButtonStates.Active
          }
          bgColor={Colors.InteractiveBGPrimary}
          onClick={() => {
            handleSubmit({
              variables: {
                params: {
                  name: infoWaitList.name,
                  email: infoWaitList.email,
                  phoneNumber: infoWaitList.phoneNumber,
                },
                eventId: event?._id,
                type: "createWaitList",
              },
            });
          }}
          loading={loading}
          icon={Icons.RightArrowRegular}
          paddingAround={"0 18px"}
          contentAlign={loading?"center" :"space-between"}
          iconPosition={ButtonIconPosition.Right}
          textTransform={FontsEnum.TextTransformNone}
          fontSize={FontsEnum.FontSize16}
          fontWeight={FontsEnum.FontWeight400}
          fontFamily={FontsEnum.FontFamily}
          letterSpacing={FontsEnum.LetterSpacing}
          disabledbuttonTheme={true}
          marginOnButtonText={true}
        />
      </ButtonContainer>
    </FullContainer>
  );
};
export default CreateWaitListInfo;
