import React from 'react';
import { CashIcon, ConfirmIcon, DiscountIcon, GuestCheckoutIcon, SalesTaxFeeIcon, ServiceFeeIcon, StripeProcessingFeeIcon } from './SvgIcons';

const iconsMap = {
  discount: DiscountIcon,
  serviceFee: ServiceFeeIcon,
  stripeProcessingIcon: StripeProcessingFeeIcon,
  salesTaxFeeIcon: SalesTaxFeeIcon,
  cashIcon:CashIcon,
  confirmIcon:ConfirmIcon,
  guestCheckoutIcon:GuestCheckoutIcon
};

export default function SvgRenderer ({ iconName, ...props }) {
  const IconComponent = iconsMap[iconName];
  return IconComponent ? <IconComponent {...props} /> : null;
};


