export enum Colors {
  White = "#FFFFFF",
  Black = "#000000",
  Black2= '#141414',
  Yellow = '#FFBA49',
  // Green = "#419D78",
  Green = '#42BB83',
  OffWhite = "#FCFCFC",
  LightBlue = "#39159C",
  Secondary= "#1E1E1E",
  Blue = "#2D0E84",
  DarkBlue = "#1D0858",
  Red = "#E63946",
  Orange = "#FF700F",
  FadedOrange = '#FFF1E7',
  LightOrange = "#FFBE93",
  DarkOrange = "#D65600",
  Grey1 = "#333333",
  Grey2 = "#4F4F4F",
  Grey3 = "#828282",
  Grey4 = "#BDBDBD",
  Grey5 = "#E0E0E0",
  Grey6 = "#F2F2F2",
  Grey7 = "#F8F8F8",
  Grey8 =  '#424242',
  Purple = "#040436",

    SecondarySurface = "#1E1E1E",
    SecondaryStroke = "#424242",
    SurfaceTertiary = "#141414",
    HeadingSecondary = "#FCFCFC",
    SupportTertiary = "#A3A3A3",
    HeadingPrimary = "#EF602E",
    InteractiveBGSecondary = "#292929",
    InteractiveBGPrimary = "#EA4513",
    TagBGTertiary = "#0C0C0C",
    TextSupportSecondary = "#D6D6D6",
    ErrorRed='#F31B43'
}
