import React, { Fragment } from "react";
import styled from "styled-components";
import * as Polished from "polished";
import { Colors } from "../Colors";
import Button, { ButtonTypes } from "./Button";

// Fixed issue SELLOUT-24
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-height: 42px;
  gap:13px;
  /* background-color: red; */
`;

type IconContainerProps = {
  active: boolean;
  justify: string;
}

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify};
  flex: 1;
  /* background-color: blue; */
  height: 100%;

  &:hover {
    cursor: ${props => (props.active ? "pointer" : null)};
  }

  .svg-inline--fa {
    color: ${props =>
      props.active ? Polished.lighten(0.025, Colors.Orange) : null} !important;
  }
`;

export type CounterProps = {
 value: number,
 maxValue?: number,
 minValue: number,
 onIncrement: Function,
 onDecrement: Function,
};

export default function Counter({
  value,
  maxValue,
  minValue = 0,
  onIncrement,
  onDecrement, 
}: CounterProps) {

  const canDecrement = value > minValue;
  const canIncrement = Boolean(!Boolean(maxValue) || (maxValue && value < maxValue));

  return (
    <Container>
        <Fragment>
          <IconContainer
            active={canDecrement}
            onClick={() => (canDecrement ? onDecrement() : null)}
            justify="flex-start"
          >
            <Button 
             type={ButtonTypes.Normal}
             text={"-"}
             bgColor={Colors.InteractiveBGSecondary}
            />
          </IconContainer>
        </Fragment>
      <IconContainer
        active={canIncrement}
        onClick={() => (canIncrement ? onIncrement() : null)}
        justify="flex-end"
      >
        <Button 
             type={ButtonTypes.Normal}
             text={"+"}
             bgColor={Colors.InteractiveBGPrimary}
            />
      </IconContainer>
    </Container>
  );
}
