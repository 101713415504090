import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AppActions from "../redux/actions/app.actions";
import styled from "styled-components";
import useShowNext from "../hooks/useShowNext.hook";
import EventInfo from "../components/EventInfo";
import TicketTypeList from "../screens/TicketTypeList";
import SelectTicketSeats from "../screens/SelectTicketSeats";
import UpgradeTypeList from "../screens/UpgradeTypeList";
import CustomFields from "../screens/CustomFields";
import LiabilityWaiver from "../screens/LiabilityWaiver";
import CustomerPhoneNumber from "../screens/CustomerPhoneNumber";
import PaymentMethod from "../screens/PaymentMethod";
import SelectPayment from "../screens/SelectPayment";
import AddPayment from "../screens/AddPayment";
import CashPayment from "../screens/CashPayment";
import ConfirmOrder from "../screens/ConfirmOrder";
import StripeTerminal from "../screens/StripeTerminal";
import OrderConfirmed from "../screens/OrderConfirmed";
import Controls from "../components/Controls";
import { PurchasePortalState } from "../redux/store";
import { ScreenEnum } from "../redux/reducers/app.reducer";
import { IEventGraphQL } from "@sellout/models/.dist/interfaces/IEvent";
import Loader, { LoaderSizes } from "@sellout/ui/build/components/Loader";
import { Colors } from "@sellout/ui/build/Colors";
import GuestMembers from "../screens/GuestMembers";
import UserEmail from "../screens/UserEmail";
import { EPurchasePortalModes } from "@sellout/models/.dist/enums/EPurchasePortalModes";

const Container = styled.div<ContainerProps>`
  position: relative;
  overflow-y: ${(props) => (props.isLoading ? "initial" : "auto")};
   height: ${(props) => {
    if (props.eventUnavilable && props.mode === EPurchasePortalModes.BoxOffice) return null
    if (props.screen === ScreenEnum.EventUnavailable) return null
    if (props.screen === ScreenEnum.OrderConfirmed) return null
    if (props.screen === ScreenEnum.CustomerPhoneNumber) return null
    if (props.showNext) return "calc(100% - 270px)"
    return "calc(100% - 190px)"
  }};
  z-index: 1000;
  background-color: ${(props) => {
    if (props.screen === ScreenEnum.CashPayment) return `${Colors.SecondarySurface}`
  }};
`;


const Placeholder = styled.div`
  height: 50px;
  background-color: transparent;
`;

const Spacer = styled.div`
  position: relative;
  height: 50px;
`;

const LoaderDiv = styled.div`
  position: absolute;
  background-color: ${Colors.SurfaceTertiary};
  top: 0px;
  width: 100%;
  z-index: 10000;
  height: 100%;
`;

const LoaderContainer = styled.div<LoaderProps>`
  width: 100%;
  height: ${(props) => props?.screen === ScreenEnum.CustomerPhoneNumber ? "calc(100% - 400px)" : "calc(100% - 190px)"};
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SessionExpired = styled.p`
  width: 100%;
  height: calc(100% - 200px);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  color: ${Colors.TextSupportSecondary};
`;

type BoxOfficeProps = {
  event: Required<IEventGraphQL>;
};

type ContainerProps = {
  isLoading: boolean;
  screen?:string;
  eventUnavilable?:boolean;
  mode?:string;
  showNext?:boolean;
};
type LoaderProps = {
  screen?:string;
};
const BoxOffice: React.FC<BoxOfficeProps> = ({ event }) => {
  /** State **/
  const showNext = useShowNext(event);
  const dispatch = useDispatch();
  const { screen, loading, SeatingPlanTimer,waitList,mode } = useSelector(
    (state: PurchasePortalState) => state.app
  );
  const [session, setSession] = React.useState(false as boolean);

  React.useEffect(() => {
    if (SeatingPlanTimer === 1) {
      setSession(true);
      dispatch(AppActions.setScreen(ScreenEnum.Tickets));
    }
  }, [SeatingPlanTimer]);
 const nextButton = showNext && screen===ScreenEnum.CustomFields && mode===EPurchasePortalModes.BoxOffice
 
  /** Render **/
  return (
    <Fragment>
      <EventInfo event={event} />
      {session ? (
        <Container isLoading={loading}>
          {" "}
          <Placeholder />
          {/* This placeholder can create a space when error  */}
          {loading && (
            <LoaderDiv>
              <LoaderContainer>
                <Loader color={Colors.Orange} size={LoaderSizes.Large} />
              </LoaderContainer>
            </LoaderDiv>
          )}
          <SessionExpired>
            Your session has expired.Please refresh the page.
          </SessionExpired>{" "}
        </Container>
      ) : (
        <Container isLoading={loading} screen={screen} eventUnavilable={waitList} mode={mode} showNext={nextButton}>
          <Placeholder />
          {/* This placeholder can create a space when error  */}
          {loading && (
            <LoaderDiv>
              <LoaderContainer screen={screen}>
                <Loader color={Colors.Orange} size={LoaderSizes.Large} />
              </LoaderContainer>
            </LoaderDiv>
          )}
          {(() => {
            switch (screen) {
              case ScreenEnum.Tickets:
                if (event.seatingChartKey) {
                  return <SelectTicketSeats event={event} />;
                } else {
                  return <TicketTypeList event={event} />;
                }

              case ScreenEnum.Upgrades:
                return <UpgradeTypeList event={event} />;

              case ScreenEnum.CustomFields:
                return <CustomFields event={event} />;

              case ScreenEnum.LiabilityWaiver:
                return <LiabilityWaiver event={event} />;

              case ScreenEnum.GuestMembers:
                return <GuestMembers event={event} />;

              case ScreenEnum.CustomerPhoneNumber:
                return <CustomerPhoneNumber />;

              case ScreenEnum.PaymentMethod:
                return <PaymentMethod event={event} />;

              case ScreenEnum.SelectPayment:
                return <SelectPayment event={event} />;

              case ScreenEnum.AddPayment:
                return <AddPayment />;

              case ScreenEnum.CashPayment:
                return <CashPayment event={event} />;

              case ScreenEnum.ConfirmOrder:
                return <ConfirmOrder event={event} />;

              case ScreenEnum.CardReader:
                return <StripeTerminal />;

              case ScreenEnum.OrderConfirmed:
                return <OrderConfirmed event={event} />;

              case ScreenEnum.UserEmail:
                return <UserEmail event={event} />;
            }
          })()}
        </Container>
      )}
      <Spacer />
      {!session && <Controls event={event} />}
    </Fragment>
  );
};

export default BoxOffice;
